'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Expander from '../Expander.react';
import BrandsFilter from '@components/Admin/Foods/Filters/BrandFilter.react';

export default class Calories extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, onChangeParams, isExpander } = this.props;

        let badge = <em data-active={false} />;

        if (params.filters?.brand_uuid) {
            badge = <em data-active={true}>1</em>
        }

        const button = (
            <span className="global-search-filter-btn">
                Brand Name {badge}
            </span>
        );

        // If we're not filtering for plans, then the plan size filter is not active
        const isFilterAvailable = params.types.includes('food');

        if (!isFilterAvailable) {
            return <span />
        }

        return (
            <span className="global-search-hideable-filter" data-active={true}>
                <Expander button={button}>
                    <BrandsFilter params={params} onChangeParams={onChangeParams} />
                </Expander>
            </span>
        );
    }
}
