'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import uuid from 'uuid';

import Popup from '@components/Widgets/Popup.react';

import Alert from '@components/Widgets/Alert/Alert.react';
import FoodUnitsSelector from '@components/Foods/FoodUnitsSelector.react';
import SearchFoodsModal from '@components/Foods/SearchFoodsModal.react';
import { getBestUnitForFood, createNewDocument, updateCachedDocuments, getRecipeEventProperties } from '@utils/Content';

import { getConfig } from '@utils/Env';
import AuthStore from '@stores/AuthStore';
import UserStore from '@stores/UserStore';
import BoardStore from '@stores/BoardStore';
import BoardActions from '@actions/BoardActions';

import Analytics from '@utils/Analytics';

import allUnits from '@tables/units';

import './QuickLogAiConfirmationModal.scss';

export default class QuickLogAiConfirmationModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        items: PropTypes.array.isRequired,
        job: PropTypes.object.isRequired,
        onConfirm: PropTypes.func.isRequired,
        mealType: PropTypes.string.isRequired,
    };

    static contextTypes = {
        editSessionId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const user = UserStore.getUser();

        this.state = {
            user,
            working: false,
            swapIndex: null,
            swapModalOpen: false,
            items: this.updateItemErrorMessages(this.props.items || []),
            confirmError: null,
        };
    }

    updateItemErrorMessages = (items) => {
        const updatedItems = items.map((item, index) => {
            let errorMessage = null;

            if (item.meal.logged_unit == '--' &&
                (!item.measurement?.amount || item.measurement?.amount <= 0)) {
                errorMessage = 'Please enter an amount and select a size';
            } else if (item.meal.logged_unit == '--') {
                errorMessage = 'Please select a size';
            } else if (!item.measurement?.amount || item.measurement?.amount <= 0) {
                errorMessage = 'Please enter an amount';
            }

            item.errorMessage = errorMessage;

            return item;
        });

        return updatedItems;
    };

    setErrorMessages = () => {
        const updatedItems = this.updateItemErrorMessages(this.state.items);

        this.setState({ items: updatedItems });
    };

    onChangeAmount = (portion, unit_description, amount, grams, milliliters, index) => {
        let { items } = this.state;
        const { job } = this.props;
        const { editSessionId } = this.context;

        Analytics.quickLogAIChangeFoodAmount({
            'Item Index': index,
            'Ingredient': items[index].ingredient,
            'Mapped UUID': items[index].content.uuid,
            'Mapped Type': items[index].content.type,
            'Old Amount': items[index].meal.logged_amount,
            'Old Unit': items[index].meal.logged_unit,
            'New Amount': amount,
            'New Unit': unit_description,
            'Job ID': job.uuid,
        });

        items[index].meal.logged_amount = amount;
        items[index].meal.logged_unit = unit_description;
        items[index].meal.logged_grams = grams;
        items[index].meal.logged_milliliters = milliliters;

        items[index].measurement = {
            amount,
            unit_of_measure: unit_description,
        };

        this.setState({ items: items, swapModalOpen: false }, this.setErrorMessages);
    };

    onClickConfirm = async () => {
        this.setState({ working: true });

        const { closeModal, mealType, onConfirm, job } = this.props;
        const { items } = this.state;
        const { editSessionId } = this.context;

        const updatedItems = this.updateItemErrorMessages(items);

        const hasErrors = updatedItems.some((item) => item.errorMessage);

        if (hasErrors) {
            this.setState({ confirmError: 'Please correct the highlighted areas above', working: false });
            return;
        }

        const processedItems = await Promise.all(
            updatedItems.map(async (item) => {
                if (item.unverifiedFood) {
                    const food = await this.storeNewFood(this.getFood(item));
                    this.addFoodToFavorites(food);
                    updateCachedDocuments([food]);

                    item.content = food;

                    delete item.unverifiedFood;

                    item.meal = {
                        uuid: uuid.v4(),
                        meal_type: 'food',
                        food_uuid: food.uuid,
                        logged_portion: 1,
                        logged_amount: food.default_unit?.amount,
                        logged_unit: food.default_unit?.description,
                        logged_grams: food.default_unit?.grams,
                        logged_milliliters: food.default_unit?.milliliters,
                    };
                }
                return item;
            })
        );

        await onConfirm(mealType, processedItems);
        closeModal();

        Analytics.quickLogAIConfirmed({
            'Job ID': job.uuid,
            ...job.parameters,
            ...getRecipeEventProperties({}, {ingredients: [{items}]})
        });
    };

    storeNewFood = async (food) => {
        food = await createNewDocument('food', food, 'food/1');

        return food;
    };

    addFoodToFavorites = (food) => {
        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: food.uuid,
            resource_type: food.type,
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }
    };

    getFood = (item) => {
        const { user } = this.state;
        const { ingredient, measurement } = item;

        const unit = allUnits[user.language].find(
            (u) =>
                (u.alts && u.alts.includes(measurement.unit_of_measure)) ||
                u.description == measurement.unit_of_measure ||
                u.plural == measurement.unit_of_measure
        );

        let food = {
            product_type: 'UGC',
            name: ingredient,
            gtin_upc: [],
            language: (user && user.language) || 'en',
            brand_name: '',
            category: null,
            nutrients: { values: {}, available: {} },
            refuse: 0,
            serving_unit: 'g',
        };

        const package_size = 1;
        const package_description = 'default package';
        const serving_amount = 1;
        const serving_description = unit?.description || 'serving';
        const serving_size = 1;

        const serving_size_float = parseFloat(serving_size);

        let milliliters, grams, pkg_milliliters, pkg_grams, serving_size_unit;

        if (unit.type == 'mass') {
            grams = serving_size_float * measurement.amount * unit.grams;
            pkg_grams = package_size * measurement.amount * unit.grams;
            serving_size_unit = 'g';
        }

        if (unit.type == 'volume') {
            milliliters = serving_size_float * measurement.amount * unit.milliliters;
            pkg_grams = package_size * measurement.amount * unit.milliliters;
            serving_size_unit = 'ml';
        }

        food.serving_description = `${serving_amount} ${serving_description} (${serving_size_float} ${serving_size_unit})`;
        food.serving_unit = serving_size_unit;
        food.milliliters_per_serving = milliliters;
        food.grams_per_serving = grams;

        food.default_unit = {
            amount: serving_amount,
            description: serving_description,
            grams,
            milliliters,
        };

        food.units = [
            {
                amount: serving_amount,
                description: serving_description,
                grams,
                milliliters,
            },
        ];

        food.packaging = [
            {
                description: package_description,
                display_mode: 'prefix-of',
                amount: 1,
                grams: pkg_grams,
                milliliters: pkg_milliliters,
                mode: 'discrete',
                units: 'metric',
            },
        ];

        return food;
    };

    onSelectFood = (food) => {
        let { items, swapIndex } = this.state;
        const { job } = this.props;
        const { editSessionId } = this.context;

        Analytics.quickLogAISwapFood({
            'Item Index': swapIndex,
            'Old Ingredient': items[swapIndex].ingredient,
            'Old Mapped UUID': items[swapIndex].content.uuid,
            'Old Mapped Type': items[swapIndex].content.type,

            'New Ingredient': food.pretty_name || food.name,
            'New Mapped UUID': food.uuid,
            'New Mapped Type': food.type,

            'Job ID': job.uuid,
        });

        items[swapIndex].content = food;
        items[swapIndex].ingredient = food.pretty_name || food.name;
        items[swapIndex].meal.meal_type = 'food';
        items[swapIndex].meal.food_uuid = food.uuid;
        delete items[swapIndex].meal.recipe_uuid;
        delete items[swapIndex].meal.details_uuid;
        delete items[swapIndex].unverifiedFood;

        const unit = getBestUnitForFood(items[swapIndex].meal.logged_unit, food);
        const { amount } = items[swapIndex].measurement || {};

        // Do we need to delete our measurements?
        if (!unit) {
            items[swapIndex].meal.logged_unit = '--';
            delete items[swapIndex].measurement;
            delete items[swapIndex].meal.logged_amount;
            delete items[swapIndex].meal.logged_grams;
            delete items[swapIndex].meal.logged_milliliters;
            delete items[swapIndex].grams;
            delete items[swapIndex].milliliters;
        } else if (items[swapIndex].measurement && food.serving_unit === 'ml' && unit.amount != 0 && amount) {
            items[swapIndex].meal.logged_milliliters = unit.milliliters / unit.amount * amount;
            items[swapIndex].milliliters = items[swapIndex].meal.logged_milliliters;

            delete items[swapIndex].meal.logged_grams;
            delete items[swapIndex].grams;
        } else if (items[swapIndex].measurement && food.serving_unit === 'g' && unit.amount != 0 && amount) {
            items[swapIndex].meal.logged_grams = unit.grams / unit.amount * amount;
            items[swapIndex].grams = items[swapIndex].meal.logged_grams;

            delete items[swapIndex].meal.logged_milliliters;
            delete items[swapIndex].milliliters;
        }

        this.setState({ items: items, swapModalOpen: false }, this.setErrorMessages);
    };

    onSelectRecipe = (recipe) => {
        let { items, swapIndex } = this.state;
        const { job } = this.props;
        const { editSessionId } = this.context;

        Analytics.quickLogAISwapFood({
            'Item Index': swapIndex,
            'Old Ingredient': items[swapIndex].ingredient,
            'Old Mapped UUID': items[swapIndex].content.uuid,
            'Old Mapped Type': items[swapIndex].content.type,

            'New Ingredient': recipe.title,
            'New Mapped UUID': recipe.uuid,
            'New Mapped Type': recipe.type,
            'Job ID': job.uuid,
        });

        items[swapIndex].content = recipe;
        items[swapIndex].ingredient = recipe.title;
        items[swapIndex].meal.meal_type = 'fresh';
        items[swapIndex].meal.recipe_uuid = recipe.uuid;
        items[swapIndex].meal.details_uuid = recipe.details;
        delete items[swapIndex].meal.food_uuid;
        delete items[swapIndex].unverifiedFood;

        const unit = getBestUnitForFood(items[swapIndex].meal.logged_unit, recipe);
        const { amount } = items[swapIndex].measurement || {};

        // Do we need to delete our measurements?
        if (!unit) {
            items[swapIndex].meal.logged_unit = '--';
            delete items[swapIndex].measurement;
            delete items[swapIndex].meal.logged_amount;
            delete items[swapIndex].meal.logged_grams;
            delete items[swapIndex].meal.logged_milliliters;
            delete items[swapIndex].grams;
            delete items[swapIndex].milliliters;
        } else if (items[swapIndex].measurement && unit.amount != 0 && amount) {
            items[swapIndex].meal.logged_grams = unit.grams / unit.amount * amount;
            items[swapIndex].grams = items[swapIndex].meal.logged_grams;

            delete items[swapIndex].meal.logged_milliliters;
            delete items[swapIndex].milliliters;
        }

        this.setState({ items: items, swapModalOpen: false }, this.setErrorMessages);
    }

    onClickSwap = (index) => {
        this.setState({ swapIndex: index, swapModalOpen: true });
    };

    onClickRemove = (index) => {
        let { items } = this.state;
        const { job } = this.props;

        let item = items[index];

        Analytics.quickLogAIDeleteFood({
            'Item Index': index,
            'Ingredient': item.ingredient,
            'Amount': item.measurement?.amount,
            'Unit': item.measurement?.unit_of_measure,
            'Job ID': job.uuid,
        });

        items.splice(index, 1);

        this.setState({ items });
    };

    closeSwapModal = () => {
        this.setState({ swapIndex: null, swapModalOpen: false });
    };

    renderSwapModal = () => {
        const { items, swapIndex, swapModalOpen } = this.state;

        if (!swapModalOpen) {
            return;
        }

        return (
            <SearchFoodsModal
                closeModal={this.closeSwapModal}
                modalTitle="Swap Food"
                inhibitSearchOnMount={false}
                allowedTypes={['food', 'recipe']}
                defaultTerms={items[swapIndex].ingredient}
                onSelectFood={this.onSelectFood}
                onSelectRecipe={this.onSelectRecipe}
                selectButtonText="Add food"
                createCustomButtonCopy="Create New Food"
            />
        );
    };

    renderItem = (item, index) => {
        const { user, items } = this.state;

        const title = item.content?.pretty_name || item.content?.name || item.content?.title || item.ingredient;

        return (
            <div key={index} className="item-container">
                <div className="quick-log-food">
                    {item.unverifiedFood ? (
                        <Popup
                            positionClassName="el-popup-left-top"
                            button={<span className="food-warning"><i className="icon-warning" /></span>}
                        >
                            <div className="food-not-found">
                                Nutritional info for this food is unavailable. Please try swapping with a similar item
                                from our database to track it.
                                <footer>
                                    <button
                                        onClick={() => this.onClickSwap(index)}
                                        className="el-medium-btn el-raspberry-btn"
                                    >
                                        Swap
                                    </button>
                                </footer>
                            </div>
                        </Popup>
                    ) : null}

                    <p className="p4">
                        {item.content?.brand_name ? <em>{item.content.brand_name}&nbsp;</em> : null}
                        {title}
                    </p>

                    <button onClick={() => this.onClickSwap(index)} className="el-medium-btn el-link-raspberry-btn">
                        swap
                    </button>

                    {items.length > 1 ? (
                        <button onClick={() => this.onClickRemove(index)} className="el-medium-btn el-link-raspberry-btn">
                            remove
                        </button>
                    ) : null}

                    {user.role === 'admin' && item.content?.type === 'food' ? (
                        <a href={`/admin/foods/${item.content.uuid}`} target="_blank">
                            <button className="el-medium-btn el-link-raspberry-btn">
                                <i className="feather feather-box" />
                            </button>
                        </a>
                    ) : null}

                    {user.role === 'admin' && item.content?.type === 'recipe' ? (
                        <a href={`/publisher/recipe/${item.content.uuid}`} target="_blank">
                            <button className="el-medium-btn el-link-raspberry-btn">
                                <i className="feather feather-box" />
                            </button>
                        </a>
                    ) : null}

                </div>

                <FoodUnitsSelector
                    showAbove={items.length > 1 && index == items.length - 1}
                    errorMessage={item.errorMessage}
                    food={item.content || {}}
                    meal={item.meal}
                    unverified={item.unverifiedFood}
                    onChangeAmount={(portion, unit, amount, grams, milliliters) =>
                        this.onChangeAmount(portion, unit, amount, grams, milliliters, index)
                    }
                />
            </div>
        );
    };

    closeModal = () => {
        const { items } = this.state;
        const { job, closeModal } = this.props;

        Analytics.closeQuickLogAIConfirmation({
            'Job ID': job.uuid,
            ...job.parameters,
            ...getRecipeEventProperties({}, {ingredients: [{items}]})
        });

        closeModal();
    }

    render = () => {
        const { working, swapModalOpen, items, confirmError } = this.state;
        const { closeModal } = this.props;

        return (
            <Modal
                isOpen={true}
                className="el-modal el-modal2 quick-log-ai-confirmation"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeModal}
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container">
                    <header>
                        <h2>Quick Add Foods</h2>
                    </header>
                    <div className="el-modal-body-container el-modal2-body-container el-fonts el-form quick-log-ai-confirmation">
                        {working ? (
                            <div
                                className="el-modal-loading el-fonts ingredients-loader"
                                data-testid="ingredients-loader"
                            >
                                <h4>Please wait</h4>
                                <i className="icon-spinner2" />
                                <span className="loading-text">Processing your food items...</span>
                            </div>
                        ) : (
                            items.map(this.renderItem)
                        )}
                    </div>
                    {confirmError ? <Alert type="error" description={confirmError} /> : null}
                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeModal}>
                            cancel
                        </button>
                        <button disabled={working} className="el-modal-ok-btn" onClick={this.onClickConfirm}>
                            {working ? 'working...' : 'confirm'}
                        </button>
                    </footer>
                </div>
                {this.renderSwapModal()}
            </Modal>
        );
    };
}
