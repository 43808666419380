'use strict';

import indexBy from 'lodash.indexby';

import AuthStore from '../stores/AuthStore';
import { getConfig } from './Env';
import { PorterStemmer } from 'natural';

import altImages from '../tables/alternate-images';
import allUnits from '../tables/units';

const _cache = {
    documents: {},
    promises: {},
    usda: {},
    usdaPromises: {},
    altImageAssignments: {},
};

export function getRecipeEventProperties(recipe, details, assets = null) {
    const traits = {
        Ingredients: [],
        'Mapped UUIDs': [],
        'Mapped Contents': [],
        'Ingredient Amounts': [],
        'Ingredient Units': [],
    };

    if (recipe?.uuid) {
        traits['Recipe UUID'] = recipe.uuid;
    }

    if (recipe?.title) {
        traits['Recipe Title'] = recipe.title;
    }

    if (recipe?.source_url) {
        traits['Recipe Source URL'] = recipe.source_url;
    }

    const missedMappings = [];
    const missedAmounts = [];

    details?.ingredients?.forEach((group) => {
        group.items?.forEach((ingredient) => {
            traits['Ingredients'].push(ingredient.ingredient);
            traits['Ingredient Amounts'].push(ingredient.measurement?.amount || null);
            traits['Ingredient Units'].push(ingredient.measurement?.unit_of_measure || null);
            traits['Mapped UUIDs'].push(ingredient.food?.uuid || ingredient.recipe?.uuid || ingredient.content?.uuid);
            traits['Mapped Contents'].push(
                ingredient.food?.name ||
                    ingredient.recipe?.title ||
                    ingredient.content?.name ||
                    ingredient.content?.title
            );

            if (!ingredient.grams && !ingredient.milliliters) {
                missedAmounts.push(
                    JSON.stringify({
                        ingredient: ingredient.ingredient,
                        amount: ingredient.measurement?.amount,
                        unit: ingredient.measurement?.unit_of_measure,
                        food_uuid: ingredient.food?.uuid,
                        recipe_uuid: ingredient.recipe?.uuid,
                    })
                );
            }

            if (!ingredient.food?.uuid && !ingredient.recipe?.uuid && !ingredient.content?.uuid) {
                missedMappings.push(
                    JSON.stringify({
                        ingredient: ingredient.ingredient,
                        amount: ingredient.measurement?.amount,
                        unit: ingredient.measurement?.unit_of_measure,
                    })
                );
            }
        });
    });

    if (missedMappings.length > 0) {
        traits['Missed Ingredient Mapping'] = missedMappings;
    }

    if (missedAmounts.length > 0) {
        traits['Missed Amount Mapping'] = missedAmounts;
    }

    return traits;
}

export function getAssignedAlternateImage(uuid) {
    if (!_cache.altImageAssignments[uuid]) {
        _cache.altImageAssignments[uuid] = altImages[Math.floor(Math.random() * altImages.length)];
    }

    return _cache.altImageAssignments[uuid];
}

export function assignDocumentAlternateImages(doc) {
    let alt;

    if (!doc) {
        return doc;
    }

    // If we don't have an image for a recipe, assign one
    if (!doc.image && doc.type === 'recipe') {
        alt = getAssignedAlternateImage(doc.uuid);
        doc.image = alt.url;
        doc.image_thumb = alt.thumb;
    }

    if (!doc.main_image && doc.type === 'combo') {
        alt = getAssignedAlternateImage(doc.main_dish);
        doc.main_image = alt.url;
        doc.main_image_thumb = alt.thumb;
    }

    if (!doc.side_image && doc.type === 'combo') {
        alt = getAssignedAlternateImage(doc.side_dish);
        doc.side_image = alt.url;
        doc.side_image_thumb = alt.thumb;
    }

    return doc;
}

export async function fetchDocumentById(uuid) {
    const docs = await fetchDocumentsById([uuid]);

    return docs && docs[0] ? docs[0] : null;
}

export function fetchDocumentsById(uuids, version = 1) {
    // De-duplicate uuids, just in case
    uuids = uuids.filter((uuid, index) => uuid && uuids.indexOf(uuid) === index);

    return new Promise((resolve, reject) => {
        var needIds = uuids.filter(uuid => uuid && typeof _cache.documents[uuid] === 'undefined');

        // If we have all the cached IDs, then just return them immediately
        if (needIds.length == 0) {
            return resolve(uuids.map(uuid => _cache.documents[uuid]));
        }

        // Collect promises for any UUIDs we have active request for (there might be more than one).
        let promises = needIds.map(uuid => _cache.promises[uuid]).filter(v => v);
        promises = promises.filter((promise, index) => promises.indexOf(promise) === index);

        // Collect any UUIDs that we don't have an active request out for (we need to AJAX those)
        let fetchUuids = needIds.filter(uuid => uuid && !_cache.promises[uuid]);

        if (fetchUuids.length > 0) {
            // Retrieve them from the database if we need to
            const fetchPromise = AuthStore.fetch(getConfig('recipe_api') + '/contents', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; schema=multi/get/1',
                    'Accept': 'application/json; schema=collection/content/' + version,
                },
                body: JSON.stringify({uuids: fetchUuids}),
            }, true).then(
                data => {
                    // Don't need these resolved promises anymore
                    fetchUuids.forEach(uuid => delete _cache.promises[uuid]);

                    // If we get back results, cache the documents
                    if (data && data.elements) {
                        data.elements = data.elements.map(assignDocumentAlternateImages);

                        const newDocs = indexBy(data.elements, 'uuid');

                        fetchUuids.forEach(uuid => _cache.documents[uuid] = newDocs[uuid] || false);
                    }
                },
                error => {
                    fetchUuids.forEach(uuid => delete _cache.promises[uuid]);
                }
            );

            promises.push(fetchPromise);

            // Store this promise as being the fetch promise for all these UUIDs
            fetchUuids.forEach(uuid => _cache.promises[uuid] = fetchPromise);
        }

        // Wait until all fetch promises are resolved, then resolve our main promise directly from cache
        Promise.all(promises).then(values => {
            return resolve(uuids.map(uuid => _cache.documents[uuid]));
        });
    });
}

export function updateCachedDocuments(documents) {
    documents.forEach(doc => {
        if (!doc.uuid) {
            return;
        }

        _cache.documents[doc.uuid] = assignDocumentAlternateImages(doc);
    });
}

export function getCachedDocumentsById(uuids) {
    return uuids.map(uuid => _cache.documents[uuid]);
}

export function createNewDocument(type, doc, schema) {
    schema = schema || (type + '/1');

    const typeMap = {'category': 'categories', 'food_details': 'food-details'};

    const endpoint = typeMap[type] || (type + 's');

    return new Promise((resolve, reject) => {
        AuthStore.fetch(getConfig('recipe_api') + '/' + endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; schema=' + schema,
                'Accept': 'application/json; schema=' + schema,
            },
            body: JSON.stringify(doc),
        }).then(data => {
            var count = 0, inhibit = false;

            const selfHref = schema == 'recipe/full/1' ? data.recipe.links.self.href : data.links.self.href;

            // Poll for the availablility of the newly created document, once every
            // half a second for 10 seconds. We should rack up 20 requests if something is going wrong
            var intervalID = setInterval(() => {
                // Do not fire again or increment until the last call results
                if (inhibit) {
                    return;
                }

                if (++count > 20) {
                    // Wait 10 seconds, query 20 times
                    clearInterval(intervalID);
                    reject({message: 'timed out'})
                    return;
                }

                // Fire an ajax call, if it succeeds, then clear the interval and
                // call the parent prop functions.
                inhibit = true;

                AuthStore.fetch(getConfig('recipe_api') + selfHref).then(document => {
                    inhibit = false;
                    clearInterval(intervalID);

                    // Resolve the promise
                    resolve(data);
                }, error => inhibit = false);
            }, 500);
        }, reject);
    });
}


function findString(searchString, stringToFind)
{
    const pos = searchString.indexOf(stringToFind)

    if (pos === -1) {
        return false;
    }

    if (pos >= 1 && (searchString[pos - 1].toLowerCase() != searchString[pos - 1].toUpperCase())) {
        // e.g. veggie !== egg
        return false;
    }

    return true;
}

/**
 * Guess at the ingredent tag avoidances based on some text.
 * Not a great place to put this function, but it's ok for now.
 *
 * @param  {string} search meta information about the food
 * @return {array}  List of ingredient tags guessed from the search.
 */
export function divineIngredientTagsFromProduct(search, categories, categoryBlacklist = []) {
    const tags = [];

    search = (search || '').toLocaleLowerCase();

    let blackListedCategories = [];
    categoryBlacklist.forEach(blacklist => {
            if (search.indexOf(blacklist['string']) !== -1 &&
                search.replaceAll(blacklist['string']).indexOf(blacklist['string']) === -1) {
                blackListedCategories = blackListedCategories.concat(blacklist['categories']);
            }
    });

    categories.forEach(category => {
        if (!blackListedCategories.includes(category.category_key)) {

            let words = [category.title].concat(category.food_names || [])
                                        .map(word => word.toLocaleLowerCase())
                                        .map(word => word.trim())
                                        .filter(word => word);
            words = words.filter((word, i) => words.indexOf(word) === i);

            words.forEach(word => {
                let stemmed = PorterStemmer.stem(word);

                if ((findString(search, stemmed) || findString(search,word)) &&
                    !tags.includes(category.category_key)) {
                    tags.push(category.category_key);
                }
            });
        }
    });

    return tags;
}

// Helper functions to filter units by type
export function getMasslikeUnits(lang = 'en') {
    lang = lang || 'en';
    const units = allUnits[lang] ? allUnits[lang] : allUnits['en'];

    return units.filter(unit => ['weight', 'mass'].includes(unit.type));
}

export function getVolumeUnits(lang = 'en') {
    lang = lang || 'en';
    const units = allUnits[lang] ? allUnits[lang] : allUnits['en'];

    return units.filter(unit => unit.type === 'volume');
}

export function getStandardUnitsForFood(food, density) {
    const standard = {};

    if (food.serving_unit === 'ml') {
        if (food.milliliters_per_serving) {
            const serving = {
                description: 'serving',
                amount: 1,
                milliliters: food.milliliters_per_serving,
            };

            if (density) {
                serving.grams = food.milliliters_per_serving * density;
            }

            standard['serving'] = serving;
            standard['servings'] = serving;
        }

        getVolumeUnits(food.language).forEach(conversion => {
            conversion.alts.forEach(alt => {
                standard[alt] = {
                    description: conversion.description,
                    amount: 1,
                    milliliters: conversion.milliliters,
                };

                if (density) {
                    standard[alt].grams = conversion.milliliters * density;
                }
            });
        });

        if (density) {
            getMasslikeUnits(food.language).forEach(conversion => {
                conversion.alts.forEach(alt => {
                    standard[alt] = {
                        description: conversion.description,
                        amount: 1,
                        milliliters: conversion.grams / density,
                    };
                });
            });
        }
    } else {
        if (food.grams_per_serving) {
            const serving = {
                description: 'serving',
                amount: 1,
                grams: food.grams_per_serving,
            };

            if (density) {
                serving.milliliters = food.grams_per_serving / density;
            }

            standard['serving'] = serving;
            standard['servings'] = serving;
        }

        getMasslikeUnits(food.language).forEach(conversion => {
            conversion.alts.forEach(alt => {
                standard[alt] = {
                    description: conversion.description,
                    amount: 1,
                    grams: conversion.grams,
                };

                if (density) {
                    standard[alt].milliliters = conversion.grams / density;
                }
            });
        });

        if (density) {
            getVolumeUnits(food.language).forEach(conversion => {
                conversion.alts.forEach(alt => {
                    standard[alt] = {
                        description: conversion.description,
                        amount: 1,
                        grams: conversion.milliliters * density,
                        milliliters: conversion.milliliters,
                    };
                });
            });
        }
    }

    return standard;
}

export function getEstimatedFoodDensity(food, custom_units = []) {
    // If we have both grams and milliliters per serving, no need to estimate or average.
    if (food.grams_per_serving && food.milliliters_per_serving) {
        return { density: food.grams_per_serving / food.milliliters_per_serving, estimate: false };
    }

    const availableUnits = (food.units || []).concat(custom_units || []);

    if ((food.units || []).length + (custom_units || []).length <= 0) {
        return { density: null, estimate: false };
    }

    const densities = [];

    const processMassUnit = ({ description = '', amount = 1, milliliters }) => {
        allUnits[food.language].forEach(({ alts, grams }) => {
            if (amount && milliliters && alts.includes(description.toLowerCase())) {
                densities.push(grams / (milliliters / amount));
            }
        });
    };

    const processVolumeUnit = ({ description = '', amount = 1, grams }) => {
        allUnits[food.language].forEach(({ alts, milliliters }) => {
            if (amount && milliliters && alts.includes(description.toLowerCase())) {
                densities.push(grams / amount / milliliters);
            }
        });
    };

    if (food.serving_unit === 'ml') {
        (food.units || []).forEach(processMassUnit);
        (custom_units || []).forEach(processMassUnit);
    } else {
        (food.units || []).forEach(processVolumeUnit);
        (custom_units || []).forEach(processVolumeUnit);
    }

    // Return the average of all volume unit conversions
    if (densities.length > 0) {
        return {
            density: densities.reduce((density, carry) => density + carry, 0) / densities.length,
            estimate: true,
        };
    }

    return { density: null, estimate: false };
}

export function getBestUnitForFood(terms, food) {
    const stripRegex = /[^a-z0-9\(\) ]/g
    let units = (food.units || []).slice()

    terms = (terms || '').toLocaleLowerCase().trim()

    if (terms == '') {
        return units.find(u => u.description == '');
    } else if (terms?.toLocaleLowerCase() === 'count') {
        return units.find(u => u.description == '');
    } else if (terms?.toLocaleLowerCase() === 'each') {
        return units.find(u => u.description == '');
    } else {
        const stemmed = PorterStemmer.stem(terms);

        units = units.map(unit => {
            let desc = unit.description.trim().toLowerCase();
            desc = desc.replace(stripRegex, '');
            const uStem = PorterStemmer.stem(desc);

            if (desc !== terms && desc !== stemmed && uStem !== terms && uStem !== stemmed) {
                return false;
            }

            return { ...unit, stemmed: uStem };
        }).filter(Boolean); // Filter out falsy values (i.e. those returned as false)

        units.sort((a, b) => {
            const aDesc = a.description;
            const bDesc = b.description;

            // Sort based on exact match to terms
            if (aDesc === terms && bDesc !== terms) {
                return -1;
            }
            if (aDesc !== terms && bDesc === terms) {
                return 1;
            }

            const aStem = a.stemmed;
            const bStem = b.stemmed;

            // Sort based on stemmed match
            if (aStem === stemmed && bStem !== stemmed) {
                return -1;
            }
            if (aStem !== stemmed && bStem === stemmed) {
                return 1;
            }

            // Finally, sort alphabetically
            return aDesc.localeCompare(bDesc);
        });

        if (units.length > 0) {
            return units[0];
        }
    }

    const density = getEstimatedFoodDensity(food, []);
    const standard = getStandardUnitsForFood(food, density);

    if (standard[terms]) {
        return standard[terms];
    }

    return null;
}

export function getDefaultPortionsForContents(contents, profile, defaultPortions = []) {
    const portions = {};

    contents.forEach(content => {
        // Assume one default portion per content, default portions are in the same format as portions and meals.
        let meal = (defaultPortions || []).find(p => (p.recipe_uuid || p.food_uuid) == content.uuid) || {};

        if (meal.logged_amount) {
            meal.logged_portion = 1;
            meal.unit = {
                description: meal.logged_unit,
                logged_unit: meal.logged_unit,
                amount: meal.logged_amount,
                grams: meal.logged_grams,
                milliliters: meal.logged_milliliters,
            };
        } else if (content.default_unit) {
            const { description, amount, grams, milliliters } = content.default_unit || {};
            Object.assign(meal, {
                logged_portion: 1,
                logged_unit: content.default_unit.description,
                logged_amount: amount,
                logged_grams: grams,
                logged_milliliters: milliliters,
                unit: content.default_unit,
            });
        } else if (content.type === 'recipe' && content.milliliters_per_serving) {
            Object.assign(meal, {
                logged_portion: 1,
                logged_amount: 1,
                logged_unit: 'serving',
                logged_milliliters: content.milliliters_per_serving,
                unit: {
                    description: 'serving',
                    amount: 1,
                    milliliters: content.milliliters_per_serving,
                },
            });
        } else {
            Object.assign(meal, {
                logged_portion: profile?.portion || 1,
                logged_amount: 1,
                logged_unit: 'serving',
                logged_grams: content.grams_per_serving,
                logged_milliliters: content.milliliters_per_serving,
                unit: {
                    description: 'serving',
                    amount: 1,
                    grams: content.grams_per_serving,
                    milliliters: content.milliliters_per_serving,
                },
            });
        }

        if (content.type === 'recipe') {
            meal.recipe_uuid = content.uuid;
            meal.meal_type = 'fresh';
        }

        if (content.type === 'food') {
            meal.food_uuid = content.uuid;
            meal.meal_type = 'food';
        }

        portions[content.uuid] = meal;
    });

    return portions;
}


export function getFoodReadyState(food) {
    const tags = food.tags || [];

    const ready = [];

    const isMissingFoodLabel = tags.includes('Missing Food Label');
    const isMissingPackaging = tags.includes('Missing Packaging');
    const isMissingDensity = tags.includes('Missing Density');

    if (tags.includes('Rejected')) {
        ready.push('rejected');
    }

    if (!food.nutrients.available['208']) {
        ready.push('nutrients');
    }

    if (food.product_type === 'UGC') {
        ready.push('ugc');
    }

    if (!food.standard_price) {
        ready.push('price');
    }

    if (isMissingDensity) {
        ready.push('density');
    }

    if (['Restaurant Dish', 'Ready Made Meal', 'Meal Kit'].includes(food.product_type)) {
        if (!food.brand_uuid) {
            ready.push('brand');
        }

        if (!food.serving_unit) {
            ready.push('serving unit');
        }

        if (!isMissingFoodLabel && !food.serving_description) {
            ready.push('serving description');
        }

        if (!isMissingFoodLabel && food.serving_unit == 'g' && !food.grams_per_serving) {
            ready.push('grams/serving');
        }

        if (!isMissingFoodLabel && food.serving_unit == 'ml' && !food.milliliters_per_serving) {
            ready.push('ml/serving');
        }

        if (!food.category) {
            ready.push('category');
        }
    } else if (['Brand Name Food'].includes(food.product_type)) {
        if (!food.brand_uuid) {
            ready.push('brand');
        }

        if (!food.serving_unit) {
            ready.push('serving unit');
        }

        if (!isMissingFoodLabel && !food.serving_description) {
            ready.push('serving description');
        }

        if (!isMissingFoodLabel && food.serving_unit == 'g' && !food.grams_per_serving) {
            ready.push('grams/serving');
        } else if (!isMissingFoodLabel &&
                  food.serving_unit == 'ml' &&
                  !food.milliliters_per_serving) {
            ready.push('ml/serving');
        }

        if (!food.units?.length) {
            ready.push('units');
        } else if (!food.default_unit) {
            ready.push('default unit');
        }

        if (!food.category) {
            ready.push('category');
        }

        if (!isMissingPackaging && !food.packaging?.length) {
            ready.push('packaging');
        }

        if (!food.gtin_upc?.length) {
            ready.push('barcode');
        }

        if (!food.nutrients.available['OLF'] &&
            !food.nutrients.available['OLG'] &&
            !food.nutrients.available['FRC'] &&
            !food.nutrients.available['LAC'] &&
            !food.nutrients.available['SOR'] &&
            !food.nutrients.available['MAN']) {
            ready.push('fodmaps');
        }
    } else if (['Food'].includes(food.product_type)) {
        if (!food.serving_unit) {
            ready.push('serving unit');
        }

        if (food.serving_unit == 'g' && !food.grams_per_serving) {
            ready.push('grams/serving');
        }

        if (food.serving_unit == 'ml' && !food.milliliters_per_serving) {
            ready.push('ml/serving');
        }

        if (!food.units?.length) {
            ready.push('units');
        } else if (!food.default_unit) {
            ready.push('default unit');
        }

        if (!food.category) {
            ready.push('category');
        }

        if (!food.nutrients.available['OLF'] &&
                  !food.nutrients.available['OLG'] &&
                  !food.nutrients.available['FRC'] &&
                  !food.nutrients.available['LAC'] &&
                  !food.nutrients.available['SOR'] &&
                  !food.nutrients.available['MAN']) {
            ready.push('fodmaps');
        }

        if (!isMissingPackaging && !food.packaging) {
            ready.push('packaging');
        }
    }

    return ready;
}

export function getContentForIngredient(ingredient, contents = {}) {
    let content;

    if (ingredient.recipe) content = contents[ingredient?.recipe?.uuid];
    if (ingredient.food) content = contents[ingredient?.food?.uuid];

    return content;
}
