'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';

import SearchTypeSelector from './AddSwapRecipe/SearchTypeSelector.react';

import RecipeDetailsModal from './RecipeDetailsModal.react';
import ComboDetailsModal from './ComboDetailsModal.react';
import FoodDetailsModal from './FoodDetailsModal.react';
import CollectionDetailsModal from './CollectionDetailsModal.react';
import QuickLogAiConfirmationModal from '../../../components/Recipes/Editor/QuickLogAiConfirmationModal.react'

import './AddSwapRecipe.scss';
import { SelectRecipe } from './AddSwapRecipe/SelectRecipe.react';
import { setRecommendedModeSourceParameters } from '../../../pro/utils/Patients';
import { fetchDocumentsById } from '../../../utils/Content';
import MealStore from '../../../stores/MealStore';

import allUnits from '../../../tables/units';

export default class AddSwapRecipe extends Component {
    static propTypes = {
        modalTitle: PropTypes.string,
        defaultTypes: PropTypes.array,
        allowedTypes: PropTypes.array,
        defaultTags: PropTypes.array,
        defaultAvoids: PropTypes.array,
        defaultExcludes: PropTypes.array,
        extraFilters: PropTypes.object,
        ideals: PropTypes.object,
        profile: PropTypes.object,
        defaultMode: PropTypes.string,
        allowedModes: PropTypes.array,
        editableParticipants: PropTypes.bool,
        editableLeftovers: PropTypes.bool,
        startAddMeal: PropTypes.func,
    };

    static defaultProps = {
        modalTitle: 'Add a Recipe',
        defaultTypes: ['recipe', 'combo'],
        allowedTypes: ['recipe', 'food', 'combo'],
        allowedModes: ['smart-choices', 'restaurants', 'favorites', 'ingredients', 'browser', 'use-own-recipe', 'create-custom'],
        defaultTags: [],
        defaultAvoids: [],
        defaultExcludes: [],
        defaultMode: 'smart-choices',
        extraFilters: {},
        ideals: null,

        createCustomParams: {},
        fullBrowserParams: {},
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
        isPro: PropTypes.bool,
        addSwapContext: PropTypes.object,
        user: PropTypes.object,
    };

    static childContextTypes = {
        showRecipeDetails: PropTypes.func,
        showComboDetails: PropTypes.func,
        showFoodDetails: PropTypes.func,
        showCollectionDetails: PropTypes.func,
        showCreateRecipe: PropTypes.func,
        showCreateCustom: PropTypes.func,
        showFullBrowser: PropTypes.func,
        startAddMeal: PropTypes.func,
        isAddSwap: PropTypes.bool,
        user: PropTypes.object
    };

    constructor(props, context) {
        super(props, context);

        const { profile, defaultMode, defaultTypes, defaultTags, defaultAvoids, defaultExcludes, extraFilters, ideals, envelope,
                createCustomParams, fullBrowserParams, allowedModes } = props;

        this.state = {
            mode: this.getDefaultAddSwapMode(allowedModes),
            params: this.getStubParams(profile, defaultMode, defaultTypes, defaultTags, defaultAvoids, defaultExcludes, extraFilters, ideals, envelope),
            isAdvancedFiltersOpen: false,
            recipe: null,
            sourceMetaData: null,
            createCustomParams,
            fullBrowserParams,
        };
    }

    getDefaultAddSwapMode = (allowedModes) => {
        const { profile, defaultMode } = this.props;

        if (defaultMode) {
            return defaultMode;
        }

        const { preferences: { default_swap_mode } } = profile;

        let defaultAddSwapMode = allowedModes[0];
        if(default_swap_mode && allowedModes.includes(default_swap_mode)) {
            defaultAddSwapMode = default_swap_mode;
        }
        return defaultAddSwapMode;
    }

    getAddSwapModes = () => {
        const { mode } = this.state;
        const { profile, allowedModes } = this.props;
        const { addSwapContext: { date, auto_log } } = this.context;
        const { inhibit_add_swap_modes } = profile?.preferences || {};

        let allowedAddSwapModes = allowedModes;
        let defaultAddSwapMode = mode;

        if(!auto_log && inhibit_add_swap_modes) {
            allowedAddSwapModes = allowedAddSwapModes.filter((mode) => !inhibit_add_swap_modes?.includes(mode));

            if(inhibit_add_swap_modes?.includes(defaultAddSwapMode)) {
                defaultAddSwapMode = allowedAddSwapModes[0];
            }
        }

        return { defaultAddSwapMode, allowedAddSwapModes };
    }

    getStubParams = (profile, mode, defaultTypes = ['recipe', 'combo'], defaultTags = [], defaultAvoids = [],
                     defaultExcludes = [], extraFilters = {}, ideals = null, envelope = null) => {

        const { user } = this.context;

        let params = {
            language: profile.language || 'en',
            types: defaultTypes,
            filters: {},
            size: 12,
            sort_by: 'ideal_smart_choices',
            sort_params: {},
            include_library: true,
            include_merchants: user?.features?.source_libraries || null,
            use_recommendations: true,
        };

        if(['browser', 'smart-choices', 'ingredients'].includes(mode)) {
            const getMode = {
                'smart-choices': 'smart_choices',
                'browser': 'search_foods_and_recipes',
                'ingredients': 'smart_choices'
            }

            setRecommendedModeSourceParameters(getMode[mode], params, profile);
        }

        if (mode === 'favorites') {
            const favoriteAllowedTags = [
                'Breakfast', 'Breakfast Side Dish', 'Lunch', 'Lunch Side Dish', 'Main Dish', 'Side Dish', 'Snack',
            ];

            Object.assign(params.filters, {
                library: true,
                tags: defaultTags.filter(t => favoriteAllowedTags.includes(t)),
            });

            return params;
        }

        const conditions = (profile.conditions || []).map(cd => cd.name);

        const alwaysExclude = [/*'Condiment/Sauce', 'Baby Food', 'Purée', 'Foundational', 'Thanksgiving', 'Christmas', 'Supplement'*/];

        Object.assign(params.filters, {
            tags: defaultTags,
            '!tags': alwaysExclude.concat(defaultExcludes || []),
            '!ingredient_tags': defaultAvoids,
        });

        Object.assign(params.filters, extraFilters);

        if (ideals && Object.keys(ideals).length > 0) {
            params.sort_params.ideals = ideals;
        }

        if (conditions && conditions.length > 0) {
            params.sort_params.conditions = conditions;
        }

        if (envelope && Object.keys(envelope).length > 0) {
            params.sort_params.envelope = envelope;
        }

        if (defaultAvoids && defaultAvoids.length) {
            params.sort_params.avoidances = defaultAvoids;
        }

        if (mode === 'smart-choices' || mode === 'ingredients') {
            params.types = ['recipe', 'combo'];
            params.size = 3;
            params.filters['!tags'].push('Exclude from Virtual');
        }

        return params;
    }

    getChildContext = () => {
        return {
            showRecipeDetails: this.showRecipeDetails,
            showComboDetails: this.showComboDetails,
            showFoodDetails: this.showFoodDetails,
            showCollectionDetails: this.showCollectionDetails,
            showCreateRecipe: this.showCreateRecipe,
            showCreateCustom: this.showCreateCustom,
            showFullBrowser: this.showFullBrowser,
            isAddSwap: true,
        };
    }

    showRecipeDetails = (recipe, sourceMetaData) => {
        this.setState({recipe, sourceMetaData});
    }

    showComboDetails = (combo, sourceMetaData) => {
        this.setState({combo, sourceMetaData});
    }

    showFoodDetails = (food, location = null, sourceMetaData) => {
        this.setState({food, location, sourceMetaData});
    }

    showCollectionDetails = (collection) => {
        this.setState({collection});
    }

    showCreateRecipe = (createRecipeParams) => {
        this.setState({createRecipeParams, mode: 'use-own-recipe'});
    }

    showCreateCustom = (createCustomParams) => {
        this.setState({createCustomParams, mode: 'create-custom'});
    }

    showFullBrowser = (fullBrowserParams) => {
        this.setState({fullBrowserParams, mode: 'browser'});
    }

    onShowAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: true});
    }

    onHideAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: false});
    }

    updateParent = () => {
        const { onChangeParams } = this.props;
        const { params } = this.state;

        onChangeParams && onChangeParams(params);
    }

    onChangeParams = (params) => {
        this.setState({params}, this.updateParent);
    }

    closeModal = () => {
        // Only clear out when collection when there is not another modal open on top of the collection modal
        this.setState((prevState) => {
          const newState = {
            recipe: null,
            combo: null,
            location: null,
            food: null,
            collection: prevState.collection,
          };

          if (prevState.recipe === null && prevState.combo === null && prevState.food === null) {
            newState.collection = null;
          }

          return newState;
        });
    }

    onChangeMode = (mode) => {
        const { profile, defaultTypes, defaultTags, defaultAvoids, defaultExcludes, extraFilters, ideals, envelope } = this.props;

        const params = this.getStubParams(
            profile,
            mode,
            defaultTypes,
            defaultTags,
            defaultAvoids,
            defaultExcludes,
            extraFilters,
            ideals,
            envelope
        );

        if (mode === 'browser') {
            params.sort_by = 'published';
            params.size = 12;
        }

        this.setState({mode, params});
    }

    renderRecipeDetailsModal = () => {
        const { recipe, sourceMetaData } = this.state;
        const { onSelectRecipe, editableLeftovers, editableParticipants } = this.props;
        const { addSwapContext } = this.context;

        if (!recipe) {
            return;
        }

        return <RecipeDetailsModal uuid={recipe.uuid}
                    closeModal={this.closeModal}
                    editableLeftovers={editableLeftovers}
                    editableParticipants={editableParticipants}
                    defaultLoggedPortion={addSwapContext.logged_portion}
                    defaultParticipants={addSwapContext.participants}
                    onSelectRecipe={onSelectRecipe}
                    sourceMetaData={sourceMetaData} />
    }

    renderComboDetailsModal = () => {
        const { combo, sourceMetaData } = this.state;
        const { onSelectCombo, editableLeftovers, editableParticipants } = this.props;
        const { addSwapContext } = this.context;

        if (!combo) {
            return;
        }

        return <ComboDetailsModal combo={combo}
                    closeModal={this.closeModal}
                    editableLeftovers={editableLeftovers}
                    editableParticipants={editableParticipants}
                    defaultParticipants={addSwapContext.participants}
                    onSelectCombo={onSelectCombo}
                    sourceMetaData={sourceMetaData} />
    }

    renderFoodDetailsModal = () => {
        const { food, sourceMetaData } = this.state;
        const { onSelectFood, editableParticipants } = this.props;
        const { addSwapContext } = this.context;

        if (!food) {
            return;
        }

        if(food.type == 'product' && food.food_uuid) {
            return <FoodDetailsModal uuid={food.food_uuid}
                        location={location}
                        closeModal={this.closeModal}
                        defaultParticipants={addSwapContext.participants}
                        editableParticipants={editableParticipants}
                        onSelectFood={onSelectFood}
                        sourceMetaData={sourceMetaData} />
        }

        return <FoodDetailsModal food={food}
                    location={location}
                    closeModal={this.closeModal}
                    defaultParticipants={addSwapContext.participants}
                    editableParticipants={editableParticipants}
                    onSelectFood={onSelectFood}
                    sourceMetaData={sourceMetaData} />
    }

    renderCollectionDetailsModal = () => {
        const { collection } = this.state;
        const { onSelectRecipe, onSelectFood } = this.props;

        if (!collection) {
            return;
        }

        return <CollectionDetailsModal collection={collection}
                    closeModal={this.closeModal}
                    onSelectRecipe={onSelectRecipe}
                    onSelectFood={onSelectFood} />
    }

    render() {
        const { profile, closeModal, modalTitle, allowedModes,
                onSelectFood, allowedTypes, useComputedParams } = this.props;
        const { params, mode, createCustomParams, fullBrowserParams } = this.state;
        const { addSwapContext } = this.context;
        const { defaultAddSwapMode,allowedAddSwapModes } = this.getAddSwapModes();

        return (
            <span>
                <Modal isOpen={true}
                    onRequestClose={closeModal}
                    closeModal={closeModal}
                    contentLabel="Add/Swap Recipe"
                    className="el-modal el-modal1 add-swap-modal"
                    overlayClassName="el-modal-overlay"
                    closeTimeoutMS={250}>
                    <section className="el-modal-container el-modal1-container add-swap-modal-container">
                        <header>
                            <button className="el-modal-back-btn" onClick={closeModal}>
                                <span>Back</span>
                            </button>

                            <h2>{modalTitle}</h2>

                            <div className="el-modal-controls el-modal1-controls">
                                <SearchTypeSelector allowedModes={allowedAddSwapModes} value={defaultAddSwapMode} onChange={this.onChangeMode} />
                            </div>
                        </header>

                        <SelectRecipe
                            mode={defaultAddSwapMode}
                            params={params}
                            onChangeParams={this.onChangeParams}
                            profile={profile}
                            allowedTypes={allowedTypes}
                            modalTitle={modalTitle}
                            useComputedParams={useComputedParams}
                            fullBrowserParams={fullBrowserParams}
                            onChangeMode={this.onChangeMode}
                            closeModal={closeModal}
                            onSelectFood={onSelectFood}
                            createCustomParams={createCustomParams}
                            mealType={addSwapContext.mealType} 
                            onLogFoods={(details) => this.onRunQuickLogAi(addSwapContext.mealType, details)}/>
                    </section>
                </Modal>

                {this.renderRecipeDetailsModal()}
                {this.renderComboDetailsModal()}
                {this.renderFoodDetailsModal()}
                {this.renderCollectionDetailsModal()}
            </span>
        );
    }
}
