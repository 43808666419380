'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import debounce from 'lodash.debounce';

import Combobox from '../../../pro/components/Widgets/Combobox.react';

import UserStore from '../../../stores/UserStore';
import AuthStore from '../../../stores/AuthStore';
import { getConfig } from '../../../utils/Env';

import '../Modals/AdvancedFilters.scss';

export default class PatientFilter extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
   };

    static contextTypes = {
        profile: PropTypes.object,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {

            working: false,
            patients: [],
            selectedPatientUuid: null,
            showPatientsList: false,
            searchTerm: '',
        };

        this.onSearchPatients = debounce(this.onSearchPatients, 300);
    }

    componentDidMount = () => {
        const { isPro, profile } = this.context;
        const { params } = this.props;
        const user = UserStore.getUser();

        if (!isPro) {
            return;
        }

        if (profile.uuid === user.uuid) {
            this.setState({ showPatientsList: true });
        }

        if (params.profile) {
            this.setState({ uuid: params.profile.uuid, defaultValue: params.profile.name});
        }


        //Always load patients even if profile is already selected
        this.getPatients(user);

    }

    getPatients = (user, searchTerm = '') => {
        const { practice } = user;

        this.setState({ working: true });

        let query; 

        if (searchTerm) {
            query = {
                terms: searchTerm,
                embed: ["preferences"],
            }
        } else {
            query = {
                terms: '',
                following: 'current',
                sort: 'last_updated',
                order: 'asc',
                embed: ["preferences"],
            }
        }

        AuthStore.fetch({
            url: getConfig('users_api') + practice.links.patients.href, query}).then(
            response => {
                this.setState({
                    patients: response.elements,
                    working: false
                });
            },
            error => {
                this.setState({
                    patients: [],
                    working: false,
                });
            }
        );
    }

    onSearchPatients = (value) => {
        const user = UserStore.getUser();
        this.setState({ searchTerm: value }, () => {
            this.getPatients(user, value);
        });
    }

    onSelectOption = (uuid) => {
        const { patients } = this.state;
        const { onChangeParams, params } = this.props;

        const profile = patients.find(patient => patient.uuid === uuid);

        params.filters = params.filters || {};
        params.filters['tags'] = [];
        params.filters['!ingredient_tags'] = [];

        (profile.preferences.diets || []).forEach(diet => {
            if (!params.filters['tags'].includes(diet)) {
                params.filters['tags'].push(diet);
            }
        });

        (profile.preferences.avoidances || []).forEach(avoid => {
            if (!params.filters['!ingredient_tags'].includes(avoid)) {
                params.filters['!ingredient_tags'].push(avoid);
            }
        });

        params.profile = {
            ...profile,
            name: `${profile.first_name} ${profile.last_name}`
        };

        onChangeParams(params);

        this.setState({ selectedPatientUuid: uuid, defaultValue: params.profile.name, openComboBox: false });
    };


    clearSelectedPatient = () => {
        const { onChangeParams, params } = this.props;

        params.filters = {};
        params.filters['tags'] = [];
        params.filters['!ingredient_tags'] = [];

        delete params.profile;

        onChangeParams(params);

        this.setState({ selectedPatientUuid: null, defaultValue: '', openComboBox: true});
    };


    render() {
        const {patients, working, showPatientsList, selectedPatientUuid, defaultValue, openComboBox } = this.state;

        const patientOpts = patients.map(patient => ({ value: patient.uuid, label: `${patient.first_name} ${patient.last_name}` }));

        if (!showPatientsList) {
            return null;
        }

        return (

            <div className="el-labeled-input">

            <label>Show Smart Choices for</label>

            {working ? <span className="loading-patients-spinner"><i className="icon-spinner2"/>{"Loading patients..."}</span>  : null}
            <Combobox 
                key={defaultValue}
                defaultClassName="el-combobox-container"
                value={selectedPatientUuid}
                placeholder="---"
                onSelectOption={this.onSelectOption}
                options={patientOpts}
                onChangeTerms={this.onSearchPatients}
                defaultValue={defaultValue}
                onClearCombobox={!working && defaultValue ? this.clearSelectedPatient : null} 
                openByDefault={openComboBox} />

             </div>

        );
    }
}