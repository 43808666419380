'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import './TagGroup.scss';
import './Checkbox.scss';
import _ from 'lodash';

const LOW_HISTAMINES = 'Low Histamines';
const LOW_HISTAMINE_INGREDIENT_AVOIDANCES = [
    'dairy',
    'gluten',
    'peanuts',
    'shellfish',
    'soy',
    'wheat',
    'non_whole30_sugar',
    'pickled-foods',
    'vinegars',
    'fruits-dried',
    'chocolate',
    'coffee',
    'walnuts',
    'cashews',
    'spinach',
    'mushrooms',
    'fungi',
    'eggplant',
    'strawberries',
    'citrus',
    'avocado',
    'tomatoes',
];
const LOW_HISTAMINE_FOOD_AVOIDANCES = ['ae8bec9e-4f70-4df2-8e62-f7e248ef288a'];

export default class TagGroup extends Component {
    static propTypes = {
        translateTagMap: PropTypes.object,
    };

    static contextTypes = {
        stripRecipeOnlyFilters: PropTypes.func,
        stripPlanOnlyFilters: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            low_histamine: false,
            ingredient_avoidances: this.props.params?.filters['!ingredient_tags'] || [],
            food_avoidances: this.props.params?.filters['!foods'] || [],
        };
    }

    componentDidMount = () => {
        this.isMatchingLowHistamineAvoidances();
    };

    componentDidUpdate = () => {
        const { ingredient_avoidances, food_avoidances } = this.state;

        const new_ingredient_avoidances = Array.from(this.props.params?.filters['!ingredient_tags'] || []);
        const new_food_avoidances = Array.from(this.props.params?.filters['!foods'] || []);

        if (
            !_.isEqual(ingredient_avoidances, new_ingredient_avoidances) ||
            !_.isEqual(food_avoidances, new_food_avoidances)
        ) {
            this.setState(
                { ingredient_avoidances: new_ingredient_avoidances, food_avoidances: new_food_avoidances },
                this.isMatchingLowHistamineAvoidances
            );
        }
    };

    addRemoveAvoidancesForLowHistmaines = (addRemove, avoidances, add) => {
        let newAvoidances = avoidances.slice();
        if (add) {
            addRemove.forEach((avoidance) => {
                if (!avoidances.includes(avoidance)) {
                    newAvoidances.push(avoidance);
                }
            });
        } else {
            newAvoidances = newAvoidances.filter((item) => !addRemove.includes(item));
        }

        return newAvoidances;
    };

    isMatchingLowHistamineAvoidances = () => {
        const { ingredient_avoidances, food_avoidances } = this.state;

        const isMatch =
            LOW_HISTAMINE_INGREDIENT_AVOIDANCES.every((avoidance) => ingredient_avoidances.includes(avoidance)) &&
            LOW_HISTAMINE_FOOD_AVOIDANCES.every((avoidance) => food_avoidances.includes(avoidance));

        this.setState({ low_histamine: isMatch });
    };

    toggleTag = (tag) => {
        const { low_histamine } = this.state;
        const { tags, params, onChangeParams, onlyWorksOn, singleSelect } = this.props;
        const { stripRecipeOnlyFilters, stripPlanOnlyFilters } = this.context;

        params.filters.tags = params.filters.tags || [];

        // Is the tag already active? We want to remove it if so.
        var i = params.filters.tags.indexOf(tag);

        // Is this a single-select? Strip out our tags from filters first if so.
        if (singleSelect) {
            params.filters.tags = (params.filters.tags || []).filter((tag) => !tags.includes(tag));
        }

        const oldTags = params.filters.tags.slice(); // make a copy of tags

        if (i == -1) {
            // Does this type only work for a specific document type?
            if (onlyWorksOn) {
                params.types = [onlyWorksOn];

                if (onlyWorksOn === 'plan') {
                    stripRecipeOnlyFilters(params);
                } else if (onlyWorksOn === 'recipe') {
                    stripPlanOnlyFilters(params);
                }
            }

            params.filters.tags.push(tag);
        } else if (!singleSelect) {
            params.filters.tags.splice(i, 1);
        }

        if (tag === LOW_HISTAMINES) {
            const ingredient_avoidances = params.filters['!ingredient_tags'] || [];
            const food_avoidances = params.filters['!foods'] || [];
            params.filters['!ingredient_tags'] = this.addRemoveAvoidancesForLowHistmaines(
                LOW_HISTAMINE_INGREDIENT_AVOIDANCES,
                ingredient_avoidances,
                !low_histamine
            );
            params.filters['!foods'] = this.addRemoveAvoidancesForLowHistmaines(
                LOW_HISTAMINE_FOOD_AVOIDANCES,
                food_avoidances,
                !low_histamine
            );
            params.filters.tags = (params.filters.tags || []).filter((t) => t !== tag);
            this.setState({ low_histamine: !low_histamine });
        }

        onChangeParams(params, oldTags);
    };

    render() {
        const { low_histamine } = this.state;
        const { tags, params, singleSelect, translateTagMap } = this.props;
        let filters = params.filters || { tags: [] };
        let filterTags = filters.tags || [];

        return (
            <div className="global-search-tag-group">
                <ul>
                    {tags.map((tag) => {
                        return (
                            <li key={tag} data-tag={tag.split(' ').join('-')}>
                                <button
                                    className={singleSelect ? 'el-radio-btn' : 'el-checkbox'}
                                    data-active={filterTags.includes(tag) || (tag == LOW_HISTAMINES && low_histamine)}
                                    onClick={(e) => this.toggleTag(tag)}
                                >
                                    {(translateTagMap && translateTagMap[tag]) || tag}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
