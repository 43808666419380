'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import store from 'store';
import debounce from 'lodash.debounce';
import Helmet from 'react-helmet';

import MealActionsWrapper from '../components/Planner/MealActionsWrapper.react';
import Header from '../components/Dashboard/Header.react';
import FeedView from '../components/Dashboard/Feed.react';
import GridView from '../components/Dashboard/Grid.react';
import BasicMealsBanner from '../components/Dashboard/BasicMealsBanner.react';
import LoginForm from '../components/Widgets/LoginForm.react';
import LoadingSplash from '../components/Dashboard/Feed/LoadingSplash.react';
import ProfileDoctorModal from '../components/Dashboard/ProfileDoctorModal.react';
import CustomizerModal from '../pro/components/Modals/CustomizerModal.react';
import PostalCodeSelector from '../components/Groceries/PostalCodeSelector.react';

import GroceryStore from '../stores/GroceryStore';
import GroceryActions from '../actions/GroceryActions';
import MealStore from '../stores/MealStore';
import MealActions from '../actions/MealActions';
import PlanStore from '../stores/PlanStore';
import PlanActions from '../actions/PlanActions';
import UserStore from '../stores/UserStore';
import UserActions from '../actions/UserActions';
import ChatStore from '../stores/ChatStore';
import ChatActions from '../actions/ChatActions';
import AuthStore from '../stores/AuthStore';
import FulfillmentProviderActions from '../actions/FulfillmentProviderActions';
import FulfillmentProviderStore from '../stores/FulfillmentProviderStore';

import Analytics from '../utils/Analytics';
import { getAssetsForMeals, getCurrentActiveMealType, getParticipantsForProfileByMealType } from '../utils/Meals';
import { getStepsForMeals } from '../utils/Tasks';
import { getTimeFormatted } from '../utils/Sunbasket';
import { roundForHumans, smartCeil, convertDecimalToFraction } from '../utils/Math';
import { toHuman } from '../utils/Duration';
import { getNextDeadline, getSunbasketSubtotalForMeals } from '../utils/Sunbasket';
import { getConfig } from '../utils/Env';
import { profileKeyComparator } from '../pro/utils/Patients';

import './Dashboard.scss';

const botJid = 'eatlove-prep-bot@chat.eatlove.is';
const properMealOrder = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];

export default class Dashboard extends Component {
    static propTypes = {};

    static defaultProps = {};

    static contextTypes = {
        router: PropTypes.object,
        confirm: PropTypes.func,
        isPro: PropTypes.bool,
    };

    static childContextTypes = {
        // User assets
        user: PropTypes.object,
        isPro: PropTypes.bool,
        recommendations: PropTypes.object,
        preferences: PropTypes.object,
        family: PropTypes.array,
        providers: PropTypes.array,

        // Meal assets
        meals: PropTypes.array,
        orders: PropTypes.array,
        plans: PropTypes.array,
        groceries: PropTypes.array,
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        merchants: PropTypes.object,
        brands: PropTypes.object,
        locations: PropTypes.object,
        synced: PropTypes.bool,
        loaded: PropTypes.bool,

        // Route options
        params: PropTypes.object,

        // Scroller options
        scrollToMeal: PropTypes.func,
        changeDefaultMealType: PropTypes.func,
        autopopulate: PropTypes.func,
        populating: PropTypes.bool,

        // Misc meal info
        defaultMealType: PropTypes.string,

        // Re-trigger sync assets (only if necessary)
        synchronizeAssets: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const meals = MealStore.getMeals();
        const orders = MealStore.getOrders();
        const plans = PlanStore.getPlans();
        const groceries = GroceryStore.getGroceries();
        const providers = FulfillmentProviderStore.getFulfillmentProviders();

        const user = UserStore.getUser();
        const { postal_code, family = [], preferences = {} } = user || {};

        const { location } = props;
        const currentView = location.query?.grid ? 'grid' : 'feed';

        this.state = {
            // User assets
            user,
            preferences,
            family,
            recommendations: UserStore.getRecommendedDailyValues(),
            providers,

            // Meal assets
            meals,
            orders,
            plans,
            groceries,
            recipes: {},
            details: {},
            foods: {},
            merchants: {},
            brands: {},
            locations: {},
            synced: false,
            loaded: false,

            defaultMealType: getCurrentActiveMealType(),

            // Other various states
            currentView,
            postal_code,
            isModalOpen: false,
            isPostalCodeModalOpen: false,
            capturePostalCodeWorking: false,
            capturePostalCodeAlert: null,
        };

        this.resetInhibitPopulate = debounce(this.resetInhibitPopulate, 20000);
    }

    getChildContext = () => {
        const { params } = this.props;
        const {
            user,
            preferences,
            recommendations,
            family,
            meals,
            orders,
            recipes,
            providers,
            plans,
            groceries,
            details,
            foods,
            merchants,
            brands,
            locations,
            synced,
            loaded,
            defaultMealType,
            populating,
        } = this.state;

        return {
            params,

            // User assets
            user,
            preferences,
            recommendations,
            family,
            providers,

            // Meal assets
            meals,
            orders,
            plans,
            groceries,
            recipes,
            details,
            foods,
            merchants,
            brands,
            locations,
            synced,
            loaded,

            // Scroller actions
            scrollToMeal: this.scrollToMeal,
            changeDefaultMealType: this.changeDefaultMealType,
            autopopulate: this.autopopulate,
            populating,

            synchronizeAssets: this.synchronizeAssets,

            defaultMealType,
        };
    };

    mealTimer = null;

    componentDidMount = () => {
        const { location } = this.props;
        const { confirm, router } = this.context;
        let { pathname, query, hash } = location;

        PlanStore.addChangeListener(this.onPlanStoreChange);
        MealStore.addChangeListener(this.onMealStoreChange);
        UserStore.addChangeListener(this.onUserStoreChange);
        GroceryStore.addChangeListener(this.onGroceryStoreChange);
        ChatStore.addChangeListener(this.onChatStoreChange);
        FulfillmentProviderStore.addChangeListener(this.onFulfillmentProviderChange);
        setTimeout(() => FulfillmentProviderActions.load(this.state.user?.postal_code));

        this.synchronizeAssets();
        this.initialAutopopulate();

        // Every 15 minutes set the current defaultMealType. This is so the default meal progress
        // through the day as time passes if the meal feed is left open.
        if (!this.mealTimer) {
            this.mealTimer = setInterval(
                () => {
                    this.setState({ defaultMealType: getCurrentActiveMealType() });
                },
                60 * 1000 * 15
            );
        }

        if (location.query?.verify) {
            confirm(
                'Thank you, your email address has been verified!',
                () => {
                    delete location.query.verify;
                    router.push({ pathname, query, hash });
                },
                () => false,
                { acceptText: 'OK', rejectText: false }
            );
        }
    };

    componentWillUnmount = () => {
        MealStore.removeChangeListener(this.onMealStoreChange);
        PlanStore.removeChangeListener(this.onPlanStoreChange);
        UserStore.removeChangeListener(this.onUserStoreChange);
        GroceryStore.removeChangeListener(this.onGroceryStoreChange);
        ChatStore.removeChangeListener(this.onChatStoreChange);
        FulfillmentProviderStore.removeChangeListener(this.onFulfillmentProviderChange);

        if (this.mealTimer) {
            clearInterval(this.mealTimer);
            this.mealTimer = null;
        }
    };

    UNSAFE_componentWillReceiveProps = () => {
        this.initialAutopopulate();
    };

    onMealStoreChange = () => {
        const meals = MealStore.getMeals();
        const orders = MealStore.getOrders();
        this.setState({ synced: false, meals, orders }, () => {
            this.synchronizeAssets();
            this.initialAutopopulate();
        });
    };

    onPlanStoreChange = () => {
        const plans = PlanStore.getPlans();
        this.setState({ plans });
    };

    onUserStoreChange = () => {
        this.setState({
            user: UserStore.getUser(),
            preferences: UserStore.getPreferences(),
            recommendations: UserStore.getRecommendedDailyValues(),
        });
    };

    onGroceryStoreChange = () => {
        this.setState({ groceries: GroceryStore.getGroceries() });
    };

    onFulfillmentProviderChange = () => {
        this.setState({
            providers: FulfillmentProviderStore.getFulfillmentProviders(),
        });
    };

    onChatStoreChange = () => {
        const chat = ChatStore.getChatWithJid(botJid);

        if (chat.totals.unread == 0 && chat.markers.read) {
            // store the last read prep in localstorage.
            store.set('prep-ahead-read-marker', chat.markers.read.format(), new Date().getTime() + 1000 * 3600 * 8);
        }
    };

    autopoulated = false;

    initialAutopopulate = async (replace = false, clear_groceries = false) => {
        const { location } = this.props;
        const { user, preferences } = this.state;
        const { router } = this.context;

        // Are we even ready to run initial autopopulate?
        if (this.autopopulated || location.pathname != '/meals' || !user || !MealStore.isLoginComplete()) {
            return;
        }

        this.autopopulated = true;

        const { capabilities = {}, completed = [], postal_code } = user;

        // Or maybe we are ready to run it, but we shouldn't.
        if (preferences.inhibit_autopopulate || !capabilities.meal_planner) {
            this.setState({ showSplash: false });
            return;
        }

        // If all meals are turned off, we should not autopopulate
        const countParticipants = (total, member) => total + member.portion;

        const breakfasts = getParticipantsForProfileByMealType(user, 'Breakfast', false).reduce(countParticipants, 0);
        const lunches = getParticipantsForProfileByMealType(user, 'Lunch', false).reduce(countParticipants, 0);
        const dinners = getParticipantsForProfileByMealType(user, 'Dinner', false).reduce(countParticipants, 0);
        const snacks = getParticipantsForProfileByMealType(user, 'Snack', false).reduce(countParticipants, 0);

        if (!breakfasts && !lunches && !dinners && !snacks) {
            return;
        }

        // If the profile is incomplete, redirect to first page of onboarding
        if (
            !completed.includes('anthro') ||
            !completed.includes('conditions') ||
            !completed.includes('energy') ||
            !completed.includes('family') ||
            !completed.includes('nutrition') ||
            !user.preferences
        ) {
            Analytics.incompleteProfile({ Completed: completed });

            router.push('/new-account');
            return;
        }

        store.set('inhibit_profile_analysis', true, new Date().getTime() + 20000);

        // Are there meals for the next 3 weeks/shopping_freq's?
        let periodStart =
            preferences.start_date && moment(preferences.start_date).isAfter(moment())
                ? moment(preferences.start_date)
                : moment();
        let firstPeriodStart = moment(periodStart);
        let periodEnd;
        let endDate = moment().add(3, 'weeks').subtract(1, 'day'),
            firstGeneratedFor = null;
        let splash = true;
        const allMeals = MealStore.getMeals();

        // Do we have any meals in the entire 3 week period?
        const anyMealInEntirePeriod = allMeals.find(
            (m) => periodStart.isSameOrBefore(m.date, 'day') && endDate.isSameOrAfter(m.date, 'day')
        );

        if (anyMealInEntirePeriod && !replace) {
            const { profile_key } = user.profile_key || {};
            const { isParticipantsChanged, isProfileChanged } = profileKeyComparator(user, profile_key);
            const accountChanged = isParticipantsChanged || isProfileChanged;

            if (accountChanged) {
                store.remove('inhibit_profile_analysis');
                this.autopopulated = false;
                return;
            }
        }

        let shouldGoToCurrentMeal = true;
        while (periodStart.isSameOrBefore(endDate, 'day')) {
            periodEnd = moment(periodStart).add(preferences.shopping_freq - 1, 'day');

            // Do we have any meals in this period?
            const meals = allMeals.filter(
                (m) => periodStart.isSameOrBefore(m.date, 'day') && periodEnd.isSameOrAfter(m.date, 'day')
            );

            if (meals.length <= 0 || replace) {
                const response = await this.autopopulate(
                    periodStart,
                    { splash, replace, clear_groceries, force: true },
                    0,
                    firstPeriodStart.isSame(periodStart, 'day')
                );

                if (!firstGeneratedFor && response) {
                    firstGeneratedFor = moment(periodStart);
                }

                if (response?.meals?.length > 0) {
                    if (shouldGoToCurrentMeal && replace) {
                        this.goToCurrentMeal();
                        shouldGoToCurrentMeal = false;
                    }
                    // Find the max date
                    periodStart = moment.max(response.meals.map((m) => moment(m.date)));
                } else {
                    return; // error from populate-meals, let the profile doctor handle it
                }
            }

            // and add 1 day
            periodStart.add(1, 'day');

            splash = false;
        }

        // Starting from the
        shouldGoToCurrentMeal = true;
        if (preferences.meal_kit_providers?.includes('sunbasket') && firstGeneratedFor) {
            if (!postal_code) {
                this.setState({ isPostalCodeModalOpen: true });
                return;
            }

            // Whats our next deadline? Starting at Sunday,
            periodStart = moment(getNextDeadline(user?.time_zone)).add(4, 'day');
            periodEnd = moment(periodStart).add(1, 'week');

            while (periodStart.isSameOrBefore(endDate, 'day')) {
                const meals = allMeals.filter(
                    (m) => periodStart.isSameOrBefore(m.date, 'day') && periodEnd.isSameOrAfter(m.date, 'day')
                );

                const assets = await getAssetsForMeals(meals);

                // What's our Sunbasket subtotal for this week?
                const subtotal = getSunbasketSubtotalForMeals(meals, assets);

                // We're only going to regenerate w/o meal kits if the week we're evaluated was generated just earlier
                // in this same function call.
                if (periodStart.isSameOrAfter(firstGeneratedFor) && subtotal > 0 && subtotal < 45) {
                    await this.autopopulate(periodStart, { replace: true, inhibit_meal_kits: true, shopping_freq: 7 });

                    if (shouldGoToCurrentMeal && replace) {
                        this.goToCurrentMeal();
                        shouldGoToCurrentMeal = false;
                    }
                }

                // and add 1 week
                periodStart.add(1, 'week');
                periodEnd = moment(periodStart).add(1, 'week');
            }
        }

        store.remove('inhibit_profile_analysis');
    };

    goToCurrentMeal = () => {
        if (this.refs.feed) {
            this.refs.feed.goToCurrentMeal();
        }
    };

    // populating = false
    inhibitPopulate = false;

    resetInhibitPopulate = () => {
        this.inhibitPopulate = false;
    };

    autopopulate = async (dateStart, opts, retryCount = 0, profileDocOnFail = true) => {
        const { location } = this.props;
        const { user, preferences, defaultMealType } = this.state;
        const {
            splash = false,
            replace = false,
            merge = false,
            clear_groceries = false,
            force = false,
            shopping_freq = null,
            inhibit_meal_kits = false,
            mealNames = null,
        } = opts;
        const { capabilities = {} } = user;

        if (preferences.inhibit_autopopulate && !force) {
            throw 'inhibited by setting';
        }

        if (UserStore.getDnt() && !force) {
            throw 'inhibited by dnt';
        }

        if (this.inhibitPopulate) {
            throw 'inhibited by recency';
        }

        if (moment().isAfter(dateStart, 'day')) {
            throw 'in the past';
        }

        if (location.pathname !== '/meals') {
            throw 'not on the meal feed';
        }

        if (this.populating) {
            throw 'already populating';
        }

        if (!capabilities.meal_planner) {
            throw 'no meal_planner capability';
        }

        // Never auto-populate in the past or more than four weeks out.
        if (moment().add(4, 'week').isBefore(dateStart)) {
            throw 'too far in future';
        }

        const ignoreFirst = moment().isSame(dateStart, 'day')
            ? properMealOrder.slice(0, properMealOrder.indexOf(defaultMealType) + (replace ? 0 : 1))
            : [];

        const postBody = {
            date_start: dateStart.format('YYYY-MM-DD'),
            ignore_first: ignoreFirst,
        };

        if (merge) {
            postBody.merge = true;
        }

        if (mealNames) {
            postBody.meals = mealNames;
        }

        if (replace) {
            postBody.replace = true;
        }

        if (clear_groceries) {
            postBody.clear_groceries = true;
        }

        if (inhibit_meal_kits) {
            postBody.inhibit_meal_kits = true;
        }

        if (shopping_freq) {
            postBody.shopping_freq = shopping_freq;
        }

        this.populating = true;
        this.setState({ showSplash: splash, populating: true });

        if (this.refs.feed?.beginAutopopulate) {
            this.refs.feed.beginAutopopulate(dateStart);
        }

        try {
            const response = await AuthStore.fetch(UserStore.getLinks().populate, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; schema=populate/meals/1' },
                body: JSON.stringify(postBody),
            });
            // Did the user get logged out in the interim? Do nothing.
            if (!UserStore.getUser()) {
                return;
            }

            const { plans, meals, profile_key } = response;

            if (clear_groceries) {
                GroceryActions.mealFeedRegen();
            }

            // First, clear both meals and the grocery list
            if (replace && response?.meals?.length) {
                // Find the max date
                const maxDate = moment.max(response.meals.map((m) => moment(m.date)));

                MealActions.mealFeedRegen(ignoreFirst, dateStart, maxDate);

                PlanActions.mealFeedRegen();
            }

            // Hydrate these items into the meal store and plan store. The context itself will update.
            PlanActions.hydratePlans(plans);
            MealActions.hydrateMeals(meals);

            if (profile_key) {
                UserActions.hydrateProfileKey(profile_key);
            }

            if (this.refs.feed?.completeAutopopulate) {
                this.refs.feed.completeAutopopulate(response, replace, splash);
            }

            Analytics.autoPopulate(dateStart.format('YYYY-MM-DD'), opts);

            return response;
        } catch (error) {
            // Did the user get logged out in the interim? Do nothing.
            if (!UserStore.getUser() || !profileDocOnFail) {
                return;
            }

            // expires in 20 seconds just in case we miss deleting it somehow, crash, page reload, etc.
            store.set('inhibit_profile_analysis', true, new Date().getTime() + 20000);

            this.setState({ profileDocParams: { error: error?.message, retryCount, dateStart, ...opts } });
        } finally {
            this.populating = false;
            this.setState({ showSplash: false, populating: false });
        }
    };

    renderFraction = ({ whole, numerator, denominator }, skipZero = false) => {
        if (!denominator && !whole && skipZero) {
            return '';
        }

        if (!denominator && !whole && !skipZero) {
            return '0';
        }

        if (denominator && !whole) {
            return `${numerator}/${denominator}`;
        }

        if (whole && !denominator) {
            return whole;
        }

        return `${whole} ${numerator}/${denominator}`;
    };

    getAmountFromStep = (step) => {
        const { user } = this.state;

        // We need to convert the grams of stuff we need to the foods default unit, if it has one.
        var amount = Math.round(step.grams_needed) + 'g';

        if (step.food && step.food.default_unit && step.food.default_unit.grams) {
            var t = smartCeil(step.grams_needed / step.food.default_unit.grams);
            let content = [
                user.units_mode === 'metric' ? roundForHumans(t) : this.renderFraction(convertDecimalToFraction(t)),
            ];

            if (step.food.default_unit.description) {
                content.push(step.food.default_unit.description);
            }

            amount = content.filter((v) => v).join(' ');
        }

        return amount;
    };

    getDaysFromStep(step) {
        // Figure out what days this step is for, we should have a list of meals
        var days = step.meals.map((meal) => moment(meal.date).format('dddd'));

        return days.filter((value, key) => days.indexOf(value) == key);
    }

    renderFoundationalRecipe = (step) => {
        const details = step.subdetails;

        if (!details) {
            return '';
        }

        // How many of the foundational recipes do we need to make?
        var quantity = Math.ceil(step.grams_needed / (step.subrecipe.grams_per_serving * step.subrecipe.servings));
        let batches = '';

        if (quantity > 1) {
            batches = `, make ${quantity} batches`;
        }

        let timing = '';

        if (step.subrecipe.total_time) {
            timing = ' (' + toHuman(step.subrecipe.total_time, true) + ')';
        }

        return (
            'Prepare ' + step.name + ' ' + batches + timing
        );
    };

    renderInstructions = (step) => {
        if (!step.details) {
            return '';
        }

        let batches = '';

        // How many of the foundational recipes do we need to make?
        if (step.scaling > 1) {
            batches = `, make ${step.scaling} batches`;
        }

        let timing = '';

        if (step.total_time) {
            timing = ' (' + toHuman(step.total_time, true) + ')';
        }

        return (
            'Prepare ' + step.name + ' ' + batches + timing
        );
    };

    renderThawStep = (step) => {
        const recipe = this.state.recipes[step.meal.recipe_uuid];

        if (!recipe) {
            return '';
        }

        return 'Thaw leftover: ' + recipe.title;
    };

    renderStep = (step) => {
        if (step.type === 'foundational') {
            return this.renderFoundationalRecipe(step);
        }

        if (step.type === 'instructions') {
            return this.renderInstructions(step);
        }

        if (step.type === 'thaw') {
            return this.renderThawStep(step);
        }

        let contents = [];

        let stepVerbs = step.prep_steps.map((prep) => prep.present_simple || prep.prep_step).join(', ');
        let prepTime = step.prep_steps.reduce((cv, prep) => {
            return (prep.prep_time || 300) + cv;
        }, 0);

        if (stepVerbs) {
            contents.push(stepVerbs);
        }

        contents.push(this.getAmountFromStep(step, 2));

        if (step.name) {
            contents.push(step.name);
        }

        let timing = '';

        if (prepTime) {
            timing = ' (' + toHuman(prepTime, true) + ')';
        }

        return contents.join(' ') + timing;
    };

    renderPrepSteps = (steps) => {
        const { user } = this.state;

        if (!user) {
            return { empty: true, rendered: null };
        }

        const { name, email } = user;

        let rendered = `Hello ${name || email}, it’s time to start prepping!\n`;
        let lastStepDay = null;

        steps.forEach((step) => {
            let stepDay = this.getDaysFromStep(step)[0];

            if (stepDay != lastStepDay) {
                rendered += `\n## For ${stepDay}'s meal:\n`;
                lastStepDay = stepDay;
            }

            rendered += '* ' + this.renderStep(step) + '\n';
        });

        return { rendered, type: 'prep-ahead' };
    };

    renderSunbasketDeadlineReminder = (steps) => {
        const { user } = this.state;

        if (!user) {
            return { empty: true, rendered: null };
        }

        return { rendered: this.renderSunbasketDeadlineMessage(steps), type: 'buy-sunbasket' };
    };

    updatePrepMessages = (steps) => {
        let messages = [];

        if (!(steps && steps.length)) {
            messages.push({
                rendered:
                    'You have no prep-ahead tasks today. Check back with me daily to chop ahead and save time. Enjoy your day!',
                type: 'prep-ahead',
            });
            this.updateChat(messages);
            return;
        }

        const prepSteps = steps.filter((step) => step.type != 'sunbasket-deadline');
        messages.push(this.renderPrepSteps(prepSteps));

        const sunbasketSteps = steps.filter((step) => step.type == 'sunbasket-deadline');
        messages.push(this.renderSunbasketDeadlineReminder(sunbasketSteps));

        this.updateChat(messages);
    };

    updateChat = (newMessages) => {
        // Do we have a chat thread with EatLove Prep already setup?
        const roster = ChatStore.getRoster();
        const chat = ChatStore.getChatWithJid(botJid);

        // Do we need to add the prep bot to the roster?
        let rosterItem = roster.find((item) => item.jid === botJid);

        // Only add the roster item if we actually have prep steps to render.
        if (!rosterItem) {
            roster.push({
                jid: botJid,
                nick: 'EatLove Prep',
                fake: true,
            });
        }

        let newChatMessages = [];

        newMessages.forEach((message) => {
            // Find the old message. Is it different than the new one? If it is, we need
            // to reset our read and receipt marker to null.
            let oldMessage = chat.messages.find((m) => m.id === message.type);
            let prepAheadMarker = store.get('prep-ahead-read-marker');

            if (prepAheadMarker) {
                chat.markers.read = moment(prepAheadMarker);
            } else if (message.empty) {
                chat.markers.read = moment();
            }

            // If we've already read this chat thread before, use the read marker as our message
            let timestamp = chat.markers.read ? moment(chat.markers.read) : moment();
            // If the message/prep list has changed, then we mark the message with the current timestamp.
            if (message.rendered && oldMessage && oldMessage.messageText != message.rendered) {
                timestamp = moment();
            }

            chat.flags.fake = true;

            if (message.rendered) {
                newChatMessages.push({
                    id: message.type,
                    from: botJid,
                    to: ChatStore.whoami(),
                    timestamp,
                    link: message.type == 'buy-sunbasket' ? { href: '/groceries', text: 'Order your meals' } : null,
                    messageText: message.rendered,
                    attachments: [],
                });
            }
        });

        chat.messages = newChatMessages;
        ChatActions.updateChat(chat);
    };

    renderSunbasketDeadlineMessage = (steps) => {
        if (!(steps && steps.length > 0)) {
            return;
        }

        const deadline = steps[0].deadline;
        const currentDate = moment().startOf('days');

        if (deadline.diff(currentDate, 'days') == 2) {
            return 'Only two days left to order your sunbasket meals for the next week.';
        }

        if (deadline.diff(currentDate, 'days') == 1) {
            return 'Only one day left to order your Sunbasket meals for the next week.';
        }

        if (deadline.diff(currentDate, 'days') == 0 && moment().isBefore(deadline)) {
            return `Last day to order your Sunbasket meals for the next week. Order by ${getTimeFormatted(deadline)} !`;
        }
    };

    synchronizeAssets = () => {
        const { meals } = this.state;

        getAssetsForMeals(meals).then(({ recipes, details, foods, merchants, brands, locations }) => {
            let steps = null;

            if (MealStore.isLoginComplete()) {
                // find just a list of the next 5 days meals.
                const startDate = moment(), endDate = moment().add(5, 'day');
                const upcomingMeals = meals.filter(
                    (meal) => startDate.isSameOrBefore(meal.date, 'day') && endDate.isSameOrAfter(meal.date, 'day')
                );

                steps = getStepsForMeals(upcomingMeals, recipes, details, foods).filter((step) =>
                    step.date.isSame(moment(), 'day')
                );

                this.updatePrepMessages(steps);
            }

            let loaded = MealStore.isRangeLoaded(moment(), moment());

            this.setState({
                recipes,
                details,
                foods,
                merchants,
                brands,
                locations,
                steps,
                synced: true,
                loaded,
            });
        });
    };

    onToggleView = () => {
        let { currentView } = this.state;

        currentView = currentView === 'grid' ? 'feed' : 'grid';

        if (currentView === 'grid') {
            Analytics.expandView({ View: 'Grid' });
        } else {
            Analytics.collapseView({ View: 'Single Meal' });
        }

        this.setState({ currentView });
    };

    changeDefaultMealType = (defaultMealType) => {
        this.setState({ defaultMealType });

        store.set('meal-feed-explicit-meal', defaultMealType, moment().add(1, 'hour').toDate().getTime());

        if (this.refs.feed && this.refs.feed.setActiveMeal) {
            this.refs.feed.setActiveMeal(moment(), defaultMealType);
        }
    };

    scrollToMeal = (date, mealType) => {
        if (this.refs.feed && this.refs.feed.scrollToMeal) {
            this.refs.feed.scrollToMeal(date, mealType);
        }
    };

    onRefreshMealFeed = (force = true, clear_groceries = true) => {
        this.autopopulated = false;

        return this.initialAutopopulate(force, clear_groceries);
    };

    closeProfileDoctor = () => {
        store.remove('inhibit_profile_analysis');
        this.inhibitPopulate = false;

        this.setState({ profileDocParams: null });
    };

    renderProfileDoctor = () => {
        const { profileDocParams } = this.state;

        if (!profileDocParams) {
            return;
        }

        const { dateStart, splash, replace, retryCount, error } = profileDocParams;

        const retryPopulate = () => {
            this.inhibitPopulate = false;

            this.setState({ profileDocParams: null });

            this.autopopulate(dateStart, { splash, replace }, retryCount + 1).then(() => {
                setTimeout(() => store.remove('inhibit_profile_analysis'), 750);
            });
        };

        return (
            <ProfileDoctorModal
                retryCount={retryCount}
                closeModal={this.closeProfileDoctor}
                retry={retryPopulate}
                error={error}
            />
        );
    };

    closeCustomizerModal = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        delete query.pid;
        router.push({ pathname, query, hash });
    };

    closePostalCodeModal = () => {
        const { preferences } = this.state;

        preferences.meal_kit_providers = [];

        UserActions.updateSpecificMeta({ preferences });

        this.setState({ isPostalCodeModalOpen: false });
    };

    renderCustomizerModal = () => {
        const { location } = this.props;
        let { query } = location;

        if (!query.pid) {
            return;
        }

        return (
            <CustomizerModal
                uuid={query.pid}
                closeModal={this.closeCustomizerModal}
                showAddToPlanner={true}
                location={location}
            />
        );
    };

    onChangePostalCode = (ev) => {
        const postalCode = ev.target.value;
        this.setState({ postal_code: postalCode });
    };

    onSavePostalCode = async () => {
        const { postal_code } = this.state;
        UserActions.updateSpecificMeta({ postal_code });

        if (!postal_code.length) {
            this.setState({ capturePostalCodeAlert: 'Please enter your postal code' });
            return;
        }

        this.setState({ capturePostalCodeWorking: true });

        const response = await AuthStore.fetch({
            url: getConfig('recipe_api') + '/providers?postal_code=' + (postal_code || ''),
        });
        const sunbasket = response?.elements.find((pv) => pv.provider_key === 'sunbasket' && pv.available);

        if (!sunbasket) {
            this.setState({
                capturePostalCodeWorking: false,
                capturePostalCodeAlert: 'Very sorry but Sunbasket delivery is not available for your postal code.',
            });

            return;
        }

        UserActions.updateSpecificMeta({ postal_code });

        this.setState({ isPostalCodeModalOpen: false, capturePostalCodeWorking: false, capturePostalCodeAlert: null });
    };

    renderPostalCodeModal = () => {
        const { isPostalCodeModalOpen, postal_code, capturePostalCodeWorking, capturePostalCodeAlert } = this.state;

        if (!isPostalCodeModalOpen) {
            return null;
        }

        return (
            <PostalCodeSelector
                isModalOpen={isPostalCodeModalOpen}
                postalCode={postal_code}
                working={capturePostalCodeWorking}
                closeModal={this.closePostalCodeModal}
                onSavePostalCode={this.onSavePostalCode}
                alertMsg={capturePostalCodeAlert}
                modalTitle="Confirm Delivery Postal Code"
                saveButtonText="SUBMIT POSTAL CODE"
                onChangePostalCode={this.onChangePostalCode}
            >
                <p className="p3">
                    To receive personalized recommendations for ready-made meals and meal kits, please enter your postal
                    code below.
                </p>
            </PostalCodeSelector>
        );
    };

    render() {
        const { user, currentView, defaultMealType, loaded, showSplash } = this.state;
        const { children } = this.props;

        if (!user) {
            return (
                <div className="my-account-login">
                    <Helmet title="Please sign-in or register | EatLove" />
                    <LoginForm defaultSignUp={false} />
                </div>
            );
        }

        return (
            <div className="dashboard-page">
                <Helmet title="EatLove" />

                <LoadingSplash isOpen={showSplash || !loaded} />

                <MealActionsWrapper ref="actions">
                    <section className="feed">
                        <Header
                            defaultMealType={defaultMealType}
                            onToggleView={this.onToggleView}
                            currentView={currentView}
                            onRefreshMealFeed={this.onRefreshMealFeed}
                        />

                        <GridView isVisible={currentView === "grid"} user={user} />
                        <FeedView isVisible={currentView === "feed"} user={user} defaultMealType={defaultMealType} ref="feed" />
                    </section>

                    <div className='footer-hill'></div>

                    {loaded ? children : null}
                </MealActionsWrapper>

                {this.renderProfileDoctor()}

                {loaded ? <BasicMealsBanner /> : null}
                {this.renderCustomizerModal()}
                {this.renderPostalCodeModal()}
            </div>
        );
    }
}
