'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import $ from 'jquery';
import uuid from 'uuid';

import Params from './Params.react';
import Results from './Results.react';
import Paginator from './Paginator.react';
import AdvancedFiltersModal from './Modals/AdvancedFilters.react';

import UserActions from '../../actions/UserActions';
import AuthStore from '../../stores/AuthStore';
import { updateCachedDocuments } from '../../utils/Content';
import { getConfig } from '../../utils/Env';
import { getDietsFromTags } from '../../utils/Diets';
import { isParticipating } from '../../utils/Meals';

import allTags from '../../tables/tags';

import './Browser.scss';
import './Filters.scss';
import Analytics from '../../utils/Analytics';


export default class Browser extends Component {
    static propTypes = {
        endpoint: PropTypes.string,

        allowedTypes: PropTypes.array,
        setAlternateUser: PropTypes.bool,

        inhibitSearchOnMount: PropTypes.bool,
        hideParams: PropTypes.bool,
    };

    static defaultProps = {
        endpoint: '/search',
        allowedTypes: ['recipe', 'combo', 'food'],
        setAlternateUser: false,
        inhibitSearchOnMount: false,
    };

    static contextTypes = {
        isPublisher: PropTypes.bool,

        addSwapContext: PropTypes.object,
    };

    static childContextTypes = {
        profile: PropTypes.object,
        setParamsToPlanOnly: PropTypes.func,
        setParamsToRecipeOnly: PropTypes.func,

        stripRecipeOnlyFilters: PropTypes.func,
        stripPlanOnlyFilters: PropTypes.func,
        onAfterUnfavorite: PropTypes.func,
        unshiftResult: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            lastParams: JSON.stringify(props.params),
            params: props.params,
            resultsParams: {},

            results: [],
            total: 0,
            loading: false,
            loaded: false,
            showMoreCount: 0,
            excessResults: [],
            recommendationsUuids: [],
        };

        this.debounceUpdateParent = debounce(this.updateParent, 500);
        this.debounceSearch = debounce(this.search, 500);
    }

    getChildContext = () => {
        return {
            profile: this.props.profile,

            setParamsToPlanOnly: this.setParamsToPlanOnly,
            setParamsToRecipeOnly: this.setParamsToRecipeOnly,

            stripRecipeOnlyFilters: this.stripRecipeOnlyFilters,
            stripPlanOnlyFilters: this.stripPlanOnlyFilters,
            onAfterUnfavorite: this.removeItemsFromResults,
            unshiftResult: this.unshiftResult,
        };
    };

    _mounted = false;

    componentWillUnmount = () => {
        this._mounted = false;
    };

    componentDidMount = () => {
        this._mounted = true;

        this.setAlternateUserFromParams(this.state.params);

        if (!this.props.inhibitSearchOnMount) {
            this.search();
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const newParams = JSON.stringify(nextProps.params);

        if (this.state.lastParams != newParams) {
            this.setState(
                {
                    params: nextProps.params,
                    lastParams: newParams,
                    showMoreCount: 0,
                    excessResults: [],
                    recommendationsUuids: [],
                },
                this.debounceSearch
            );
        }
    };

    componentWillUnmount = () => {};

    browser = null;
    scroller = null;

    browserRealized = (browser) => {
        if (!browser && this.scroller) {
            this.scroller.removeEventListener('scroll', this.handleScroll);
        }

        this.browser = browser;
        this.scroller = $(browser).closest('.scroll-container')[0];

        if (this.scroller) {
            this.scroller.addEventListener('scroll', this.handleScroll);
        }
    };

    removeItemsFromResults = (itemsToRemove) => {
        let { results } = this.state;

        results = results.filter((result) => !itemsToRemove.find((item) => item.resource_id === result.uuid));

        this.setState({ results });
    };

    handleScroll = (ev) => {
        const $scrollable = $(ev.target);

        if (ev.target.scrollHeight - ev.target.scrollTop < $scrollable.innerHeight() * 2) {
            $(this.browser).find('.browser-pager').trigger('click');
        }
    };

    stripRecipeOnlyFilters = (params) => {
        params.filters = params.filters || {};

        // Strip out any tags that apply only to recipes
        const recipeOnlyTags = allTags.mealTypes.tags
            .concat(allTags.recipeMisc.tags, allTags.equipment.tags)
            .filter((tag) => {
                // Are there any tags in there that also apply to meal plans? We don't want to
                // strip those out since they can apply to both.
                return !allTags.planMisc.tags.includes(tag);
            });

        params.filters.tags = (params.filters.tags || []).filter((tag) => !recipeOnlyTags.includes(tag));

        // Delete any calories filter
        delete params.filters.calories;
        delete params.filters.hands_on_time;
        delete params.filters.servings;
        delete params.filters.sizes;

        return params;
    };

    setParamsToPlanOnly = (params) => {
        params.types = ['plan'];

        return this.stripRecipeOnlyFilters(params);
    };

    stripPlanOnlyFilters = (params) => {
        // Just to be safe
        params.filters = params.filters || {};

        // Strip out any tags that apply only to recipes
        const planOnlyTags = [].concat(allTags.planMisc.tags).filter((tag) => {
            // Are there any tags in there that also apply to recieps?
            // We don't want to strip those out since they can apply to both.
            return !allTags.recipeMisc.tags.includes(tag);
        });

        params.filters.tags = (params.filters.tags || []).filter((tag) => !planOnlyTags.includes(tag));

        // Strip out any tags that apply only to meal plans
        delete params.filters.breakfasts;
        delete params.filters.lunches;
        delete params.filters.dinners;
        delete params.filters.snacks;

        return params;
    };

    setParamsToRecipeOnly = (params) => {
        params.types = ['recipe'];

        return this.stripPlanOnlyFilters(params);
    };

    updateParent = () => {
        const { params } = this.state;
        const { onChangeParams } = this.props;

        onChangeParams && onChangeParams(params);
    };

    unshiftResult = (result) => {
        const { results } = this.state;
        const existing = results.find((r) => r.uuid === result.uuid);

        // If our result is not already in
        if (!existing) {
            results.unshift(result);
        } else {
            results[results.indexOf(existing)] = result;
        }

        this.setState({ results });
    };

    searchKey = null;

    addExclusionFilters = (results, filters = {}) => {
        filters['!uuid'] = filters['!uuid'] || [];
        filters['!main_dish'] = filters['!main_dish'] || [];
        results.forEach((r) => {
            if (r.type === 'recipe') {
                filters['!uuid'].push(r.uuid);
                filters['!main_dish'].push(r.uuid);
            }

            if (r.type === 'combo') {
                filters['!uuid'].push(r.main_dish);
                filters['!main_dish'].push(r.main_dish);
            }
        });

        return filters;
    };

    search = () => {
        const { endpoint, onExecuteSearch } = this.props;
        const { addSwapContext } = this.context;

        // Deep clone parameters
        const params = JSON.parse(JSON.stringify(this.state.params));

        this.setState({ loading: true, results: [] });

        const localKey = (this.searchKey = uuid.v4().substring(0, 8));

        AuthStore.fetch(
            getConfig('recipe_api') + endpoint,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
                body: JSON.stringify(params),
            },
            true
        ).then(
            (results) => {
                // Courtesy update of in-memory caching
                if (results && results.elements) {
                    updateCachedDocuments(results.elements);
                }

                // No longer relevant results got returned, ignore them.
                if (localKey !== this.searchKey) {
                    return;
                }

                onExecuteSearch && onExecuteSearch(params);

                let excessResults = [];
                let displayResults = results.elements;
                const recommendations = displayResults.filter((res) => res.result_type === 'recommended');
                const recommendationsUuids = recommendations.map((res) => res.uuid);

                if (results.elements.length > 50 && addSwapContext) {
                    displayResults = results.elements.slice(0, 50);
                    excessResults = results.elements.slice(50);
                }

                this.setState({
                    results: displayResults,
                    excessResults,
                    recommendationsUuids,
                    suggestion: results.suggestion,
                    total: results.total,
                    resultsParams: params,
                    loading: false,
                    loaded: true,
                });
            },
            (error) => this.setState({ loading: false })
        );
    };

    showMore = () => {
        const { endpoint } = this.props;
        const { results, loadingMore, excessResults, showMoreCount, recommendationsUuids } = this.state;
        const { addSwapContext } = this.context;

        Analytics.addSwapShowMore({ 'Search Tool': 'Search Foods and Recipes' });

        if (loadingMore) {
            return;
        }

        if (results.length >= 500) {
            return;
        }

        const params = JSON.parse(JSON.stringify(this.state.params));

        const clickCount = showMoreCount + 1;

        this.setState({ loadingMore: true, showMoreCount: clickCount });

        if (endpoint === '/search-uniques') {
            this.addExclusionFilters(results, params.filters);
        } else {
            params.from = results.length;
            params.filters['!uuid'] = recommendationsUuids;
        }

        AuthStore.fetch(
            getConfig('recipe_api') + endpoint,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
                body: JSON.stringify(params),
            },
            true
        ).then(
            (data) => {
                // Courtesy update of in-memory caching
                if (data && data.elements) {
                    updateCachedDocuments(data.elements);
                }

                const combinedResult = results.concat(excessResults, data.elements);
                const excessResultsStartIndex = results.length + data?.elements?.length;
                const displayResults = combinedResult.slice(0, excessResultsStartIndex);

                this.setState({
                    results: displayResults,
                    loading: false,
                    loadingMore: false,
                    excessResults: combinedResult.slice(excessResultsStartIndex),
                });
            },
            (error) => {
                this.setState({ loading: false, loadingMore: false });
            }
        );
    };

    getDayCount = (params) => {
        let fs = [params.filters.breakfasts, params.filters.lunches, params.filters.dinners].filter(
            (v) => v && v.gte > 0
        )[0];

        return fs ? fs.gte : false;
    };

    setAlternateUserFromParams = (params) => {
        const { profile } = this.props;

        if (!this.props.setAlternateUser) {
            return;
        }

        const altUser = {
            conditions: [],
            preferences: {},
            prescriptions: [],
            family: [],
            ...profile,

            // Always imagine that we're fully complete
            completed: ['taste', 'meals', 'conditions', 'energy', 'nutrition'],
        };

        const { participants, filters = {} } = params;

        // Has the user chosen a meal plan size? Use that.
        const planSize = this.getDayCount(params) || 3;

        // If the participants are not set, use the family as-is
        if (!participants) {
            altUser.family = profile.family;
        } else {
            altUser.preferences.breakfasts = isParticipating(participants, profile, 'Breakfast') ? planSize : 0;
            altUser.preferences.lunches = isParticipating(participants, profile, 'Lunch') ? planSize : 0;
            altUser.preferences.dinners = isParticipating(participants, profile, 'Dinner') ? planSize : 0;
            altUser.preferences.snacks = isParticipating(participants, profile, 'Snack') ? planSize : 0;
            // If the participants ARE set, it means we're changing whose eating what, and the profile
            // needs to reflect that so the customizer can display the correct information for the familys profile.
            // Iterate through each family member and set their participation flags based on participants array.

            altUser.family = profile.family.map((member) => {
                let breakfasts = isParticipating(participants, member, 'Breakfast'),
                    lunches = isParticipating(participants, member, 'Lunch'),
                    dinners = isParticipating(participants, member, 'Dinner'),
                    snacks = isParticipating(participants, member, 'Snack');

                // The customizer will re-derive the participation flags for the family members
                // from their meal participation flags
                return {
                    ...member,
                    breakfasts,
                    lunches,
                    dinners,
                    snacks,
                };
            });
        }

        if (filters.tags) {
            // get the diet tags out of the list of tags and apply them to the diet preference
            altUser.preferences.diets = getDietsFromTags(filters.tags).map((d) => d.name);
        }

        if (filters['!ingredient_tags']) {
            altUser.preferences.avoidances = filters['!ingredient_tags'];
        }

        if (filters.prescriptions) {
            altUser.prescriptions = filters.prescriptions;
        } else {
            altUser.prescriptions = [];
        }

        // Set the alternate user with a deep copy
        UserActions.setAlternateUser(altUser);
    };

    onChangeParams = (params, immediate = false) => {
        this.setAlternateUserFromParams(params);

        this.setState({ params }, () => {
            if (immediate) {
                this.updateParent();
            } else {
                this.debounceUpdateParent();
            }
        });
    };

    renderAdvancedFiltersModal = () => {
        const { total, loading } = this.state;
        const { params, allowedTypes, hideLibraryFilter, showTypePicker, isAdvancedFiltersOpen, contextName } =
            this.props;

        if (!isAdvancedFiltersOpen) {
            return null;
        }

        return (
            <AdvancedFiltersModal
                contextName={contextName}
                params={params}
                total={total}
                loading={loading}
                allowedTypes={allowedTypes}
                hideLibraryFilter={hideLibraryFilter}
                showTypePicker={showTypePicker}
                onChangeParams={this.onChangeParams}
                closeModal={this.props.onHideAdvancedFilters}
                onHideAdvancedFilters={this.onHideAdvancedFilters}
            />
        );
    };

    setTerms = (terms) => {
        const { params } = this.state;

        params.terms = terms;

        this.onChangeParams(params);
    };

    renderDidYouMean = () => {
        const { suggestion, resultsParams } = this.state;

        if (!suggestion) {
            return null;
        }

        const suggestionStr = suggestion.map((s) => s.term).join(' ');
        const cleanTerms = (resultsParams.terms || '').replace(/[^0-9a-z ]/gi, '').trim();

        if (
            cleanTerms.toLowerCase() ===
            suggestionStr
                .toLowerCase()
                .replace(/[^0-9a-z ]/gi, '')
                .trim()
        ) {
            return null;
        }

        return (
            <p className="results-suggestion">
                Did You Mean:
                <a onClick={() => this.setTerms(suggestionStr)} className="did-you-mean">
                    {suggestion.map((term, key) => {
                        return term.corrected ? <b key={key}>{term.term}</b> : <span key={key}>{term.term}</span>;
                    })}
                </a>
                ?
            </p>
        );
    };

    render() {
        const {
            allowedTypes,
            extraFilters,
            onShowAdvancedFilters,
            hideParams,
            hideLibraryFilter,
            showTypePicker,
            noResultsCopy,
            noResulsNoTermsCopy,
            loadingMessage,
            contextName,
        } = this.props;
        const { results, resultsParams, total, loaded, loading, loadingMore, params, recommendationsUuids } = this.state;

        const ResultsComponent = this.props.resultsComponent || Results;
        const searchTerm = params.terms;
        const recommendedResults = recommendationsUuids.length;

        return (
            <div className="global-search-browser" ref={this.browserRealized}>
                {extraFilters}

                {!hideParams ? (
                    <Params
                        params={resultsParams}
                        loading={loading}
                        allowedTypes={allowedTypes}
                        total={total}
                        hideLibraryFilter={hideLibraryFilter}
                        showTypePicker={showTypePicker}
                        onShowAdvancedFilters={onShowAdvancedFilters}
                        onChangeParams={this.onChangeParams}
                    />
                ) : null}

                {this.renderDidYouMean()}

                <ResultsComponent
                    params={resultsParams}
                    loadingMessage={loadingMessage}
                    onChangeParams={this.onChangeParams}
                    noResultsCopy={!searchTerm ? noResulsNoTermsCopy || noResultsCopy: noResultsCopy}
                    hideCreateCustom={contextName == "Saved Recipes" && searchTerm?.length > 0}
                    overrideCreateCustomButtonCopy={contextName == "Saved Recipes" && !searchTerm ? "add your own recipe" : null}
                    results={results}
                    total={total}
                    loaded={loaded}
                    loading={loading}
                    searchTerm={searchTerm}
                    recommendedResults={recommendedResults}
                />

                <div className="global-search-paginator">
                    <Paginator
                        loading={loading}
                        loadingMore={loadingMore}
                        total={total}
                        results={results}
                        resultsParams={resultsParams}
                        showMore={this.showMore}
                    />
                </div>

                {this.renderAdvancedFiltersModal()}
            </div>
        );
    }
}
